import { colorBlack, hydrangea3 } from "component/color"

// Parent feature to group all the features that are related to the Topic of the Moment.
export const TopicOfTheMomentConfigFeature = {
    key: 'topicofthemomentconfig',
    name: 'Topic of the moment',
    defaultConfig: {
        topicTopWidgets: [],
        topicBottomWidgets: [],
        headerBackgroundColor: hydrangea3,
        headerFontColor: colorBlack,
    }
}

// Parent feature to group all the features that are related to the Ask Me Anything.
export const AskMeAnythingConfigFeature = {
    key: 'askmeanything',
    name: 'Ask us anything',
    defaultConfig: {
        topicTopWidgets: [],
        topicBottomWidgets: [],
        headerBackgroundColor: hydrangea3,
        headerFontColor: colorBlack,
    }
}