import React from "react";
import { HorizBox, Pad, PadBox } from "component/basics.js"
import { UtilityText } from "component/text.js"
import { useDatastore, usePersonaKey } from "util/datastore.js"
import { RatingSelector } from "../../component/ratingselector";
import { StyleSheet, View } from "react-native";
import { colorBlack, colorGreyBorder, colorGreyPopupBackground, colorWhite } from "component/color.js"
import { REPLACE, useConfig } from "util/features.js"
import { logEventAsync } from "util/eventlog.js"
import { PollResult } from "../cbcrc/poll/poll";
import { PollPicker } from "../../component/pollpicker";
import { ColoredBallPill } from "../../component/colorpill";

export const CommentSliderFeature = {
    name: 'Comment Slider',
    key: 'commentslider',
    config: {
        pageTopWidgets: [CommentSliderVisualizationRedirect],
        commentFilters: [getCommentsWithSelectedPosition],
        commentEditTopWidgets: [Selector],
        commentAboveWidgets: [State],
        commentAllowEmpty: true,
        commentPostBlockers: [({comment}) => comment.replyTo ? !comment.text : !comment.slider],
        composerSubtitle: () => 'Public response',
    },
    defaultConfig: {
        commentSliderLabels: ['Strongly no', 'No with reservations', "It's complicated", "Yes with reservations", "Strongly yes"],
        commentSliderVisualization: BarChartVisualization,
        commentSliderSelector: RatingSelector,
        commentSliderDefault: 3,
    }
} 

export const AgreeDisagreeFeature = {
    name: 'Agree/Disagree',
    parentFeature: 'commentslider',
    dev: true,
    key: 'commentslider_agree',
    config: {
        commentSliderLabels: REPLACE(['Strongly disagree', 'Somewhat disagree', "It's complicated", 'Somewhat agree', 'Strongly agree'])
    }
}

export const YesNoFeature = {
    name: 'Yes/No',
    parentFeature: 'commentslider',
    dev: true,
    key: 'commentslider_yes',
    config: {}
}

export const CommentSliderDisableAnonymousFeature = {
    name: 'CS Disable Anonymous',
    key: 'commentslider_disable_anonymous',
    parentFeature: 'commentslider',
    config: {
        commentAllowEmpty: false,
        composerSubtitle: () => 'Public response'
    }
}

export const CommentSliderEnableVisualizationFeature = {
    name: 'Data Viz',
    key: 'commentslider_enable_visualization',
    parentFeature: 'commentslider',
}

export const CommentSliderHorizVisualizationFeature = {
    name: 'Horizontal Data Viz',
    key: 'commentslider_horiz_visualization',
    parentFeature: 'commentslider_enable_visualization',
    dev: true,
    config: {
        commentSliderVisualization: HorizBarVisualization,
    }
}

export const CommentSliderVertVisualizationFeature = {
    name: 'Vertical Data Viz',
    key: 'commentslider_vert_visualization',
    parentFeature: 'commentslider_enable_vizualization',
    dev: true,
    config: {}
}

export const CommentSliderPollPickerFeature = {
    name: 'Poll Picker',
    key: 'commentslider_poll_picker',
    parentFeature: 'commentslider',
    config: {
        commentSliderSelector: PollPicker
    }
};

export const CommentSliderSliderFeature = {
    name: 'Slider',
    key: 'commentslider_slider',
    parentFeature: 'commentslider',
    config: {}
}

export const CommentSliderDefaultFeature = {
    name: 'CS Default',
    key: 'commentslider_default',
    parentFeature: 'commentslider',
    config: {
        commentSliderDefault: 3
    }
}


const colorStronglyYes = '#068287';
const colorYesWithReservations = '#00af88';
const colorItsComplicated = '#ffc841';
const colorNoWithReservations = '#fa7355';
const colorStronglyNo = '#f53c18';

const ratingColors = [
    colorStronglyNo,
    colorNoWithReservations,
    colorItsComplicated,
    colorYesWithReservations,
    colorStronglyYes
]


function CommentSliderVisualizationRedirect({comments}) {
    const {commentSliderVisualization} = useConfig();
    const ratingCounts = countRatings(comments);
    const maxCount = Math.max(...Object.values(ratingCounts));
    const commentsWithSlider = comments.filter(c => c.slider);
    const responseCount = commentsWithSlider.filter(c => c.text).length;
    const voteCount = commentsWithSlider.length - responseCount;

    if (commentsWithSlider.length == 0) {
        return null;
    }

    return React.createElement(commentSliderVisualization, {ratingCounts, commentsWithSlider, maxCount, responseCount, voteCount});
}

export function HorizBarVisualization({commentsWithSlider}) {
    const {commentSliderLabels} = useConfig();
    const personaKey = usePersonaKey();
    const votes = commentsWithSlider.map(c => commentSliderLabels[c.slider-1]);
    const myAnswer = commentsWithSlider.find(comment => comment.from == personaKey && comment.replyTo == null);
    const myVote = commentSliderLabels[myAnswer?.slider -1];
    return <PadBox horiz={20} top={20}>
        <PollResult translateOptions options={commentSliderLabels} myVote={myVote} votes={votes} visualizationType='percent' />
    </PadBox>
}


export function BarChartVisualization({ratingCounts, maxCount, responseCount, voteCount}) {
    const s = BarChartStyle;
    const {commentSliderLabels, commentAllowEmpty} = useConfig();

    return <PadBox top={20} horiz={10}><View style={s.outer}>
        <HorizBox>
            <Bar color={colorStronglyNo} value={0} count={ratingCounts[0]} maxCount={maxCount} />
            <Bar color={colorNoWithReservations} value={1} count={ratingCounts[1]} maxCount={maxCount} /> 
            <Bar color={colorItsComplicated} value={2} count={ratingCounts[2]} maxCount={maxCount} />
            <Bar color={colorYesWithReservations} value={3} count={ratingCounts[3]} maxCount={maxCount} />
            <Bar color={colorStronglyYes} value={4} count={ratingCounts[4]} maxCount={maxCount} />
        </HorizBox>
        <View style={s.bottomAxis} />
        <Pad size={10} />
        <HorizBox spread>
            <UtilityText type='tiny' weight='strong' label={commentSliderLabels[0]}/>
            <PadBox horiz={20}><UtilityText center type='tiny' weight='strong' label="It's complicated"/></PadBox>
            <UtilityText right type='tiny' weight='strong' label={commentSliderLabels[4]}/>
        </HorizBox>
        {commentAllowEmpty && <PadBox top={20}><UtilityText label='Showing {responseCount} public responses and {voteCount} anonymous votes' 
            formatParams={{responseCount, voteCount}} /></PadBox>}
    </View></PadBox>
}

function Bar({color, value, count, maxCount}) {
    const height = count / maxCount * 80;

    return <View style={{flex: 1, height: 100, justifyContent: 'flex-end'}}>
        <View style={{backgroundColor: color, height, marginHorizontal: 2}} />
        {height >= 30 && 
            <View style={{position: 'absolute', bottom: height-24, left: 0, right: 0, alignItems: 'center'}}>
                <UtilityText text={count} weight='medium' color={colorWhite} />
            </View>
        }
        {count > 0 && height < 30 && 
            <View style={{position: 'absolute', bottom: height+4, left: 0, right: 0, alignItems: 'center'}}>
                <UtilityText text={count} weight='medium' color={colorBlack} />
            </View>
        }
    </View>
}

const BarChartStyle = StyleSheet.create({
    outer: {
        backgroundColor: colorGreyPopupBackground,
        borderRadius: 8,
        padding: 20
    },
    bottomAxis: {
        borderTopColor: colorGreyBorder,
        borderTopWidth: 2
    }
});

function getCommentsWithSelectedPosition({datastore, comment}) {
    const selection = datastore.getSessionData(['commentslider', 'selection']); 
    if (!comment.text) {
        return false;
    } else if (selection != null) {
        return comment.slider == selection;
    } else {
        return true;
    }
}

export function Selector({comment, setComment}) {
    const {commentSliderLabels, commentSliderSelector, commentSliderDefault} = useConfig();
    const datastore = useDatastore();
    if (comment.replyTo) return null;
    function onSelectPosition(position) {
        setComment({...comment, slider: position});
        if (!comment.slider) {
            logEventAsync(datastore,'comment-slider-pick', {text:position});
        }
    }
    return <PadBox bottom={12}>
        {React.createElement(commentSliderSelector, {
            value: comment.slider, options: commentSliderLabels, optionColors: ratingColors,
            defaultValue: commentSliderDefault, translateOptions: true,
            placeholder: 'Slide to respond:', onChangeValue: onSelectPosition})           
        }
        <Pad size={24} />
        <UtilityText weight='medium' label='Explain your response' />
    </PadBox>
}

function State({comment}) {
    const s = StateStyle;
    const {commentSliderLabels} = useConfig();
    if (comment.slider) {        
        return <PadBox bottom={20}><ColoredBallPill label={commentSliderLabels[comment.slider-1]} color={ratingColors[comment.slider-1]} /></PadBox>
    } else {
        null;
    }
}
const StateStyle = StyleSheet.create({
    tag: {
        marginBottom: 20,
        backgroundColor: colorGreyPopupBackground,
        borderRadius: 100,
        paddingHorizontal: 12,
        paddingVertical: 6,
        alignSelf: 'flex-start'
    }
});


function countRatings(posts) {
    var ratingCounts = [0,0,0,0,0];
    for (const post of posts) {
        if (post.slider) {
            ratingCounts[post.slider-1] += 1;
        }
    }
    return ratingCounts;
}

