import { Chat, Flag, IdManagement, Link, Time } from "@carbon/icons-react";
import { useEffect, useMemo, useState } from "react";
import { Image } from "react-native";
import { StyleSheet, View } from "react-native";
import { TabBar, TabContent, TabNavigation } from "../../../contrib/zdf/tabnavigation";
import { ModDashboardQueue } from "./moddashboardqueue";
import { keyToUrl } from "../../../util/util";
import { REPLACE_ZDF_LinkText } from "../text";
import { ParentDatastoreProvider } from "../parentdatastore";
import { useModerationAuthorsByIds } from "../moderation";
import { REPLACE_ZDF_FilterButton } from "../button";
import { useCollection, useDatastore, useInstanceKey, useModulePublicData, usePersonaObject } from "../../../util/datastore";
import { EmbeddedInstance } from "../../../util/instance";
import { colorGreyPopupBackground, colorLightGreen, colorRed, colorRedBackground, colorTextBlue, colorTextGrey, colorWhite } from "../../color";
import { Center, HorizBox, HoverView, LoadingScreen, Pad, PadBox }  from "../../basics";
import { Heading, Paragraph, UtilityText } from "../../text";
import { SpacedArray } from "../../../system/demo";
import { gotoInstance } from "../../../util/navigate";
import { CTAButton, DropDownSelector, Tag, TextButton } from "../../button";
import { Modal } from "../../modal";
import { getQueueSubsetCounts, useFilteredQueues } from "./moddashboardqueuehooks";
import { useInstanceParams } from "../../../util/params";

const useModerationThreads = ({ count = 1, sortOrder = "oldest", filters = {} }) => {

    const questionThreads = useModulePublicData("question");
    const [allThreads, setAllThreads] = useState(null);
    const [sortedThreads, setSortedThreads] = useState(null);
    const [remainingThreadCount, setRemainingThreadCount] = useState(0);
    const [newThreadCount, setNewThreadCount] = useState(0);
    const [time, setTime] = useState(sortOrder === "newest" ? Date.now() : 0);

    useEffect(() => {
        if (!questionThreads) {
            return;
        }

        // Convert the question threads into a list of objects with their keys
        const threadObjectsWithKey = Object.entries(questionThreads).map(([key, value]) => ({ key, ...value }));
        setAllThreads(threadObjectsWithKey);

        // Count new threads (those newer than the given timestamp)
        const newThreads =
            sortOrder === "newest"
                ? threadObjectsWithKey.filter((thread) =>
                      sortOrder === "newest" ? thread.time > time : thread.time < time
                  )
                : [];
        // Set the new thread count
        setNewThreadCount(newThreads.length);


        // Applying filters

        // Multiple filters can be selected at once. In that case, we need to combine the lists of threads.
        let combinedFilteredThreads = [];

        if (filters && filters.open) {
            combinedFilteredThreads = combinedFilteredThreads.concat(
                Object.values(threadObjectsWithKey).filter(
                    (thread) => thread.readonly === false || thread.readonly === undefined
                )
            );
        }

        if (filters && filters.closed) {
            combinedFilteredThreads = combinedFilteredThreads.concat(
                Object.values(threadObjectsWithKey).filter((thread) => thread.readonly === true)
            );
        }

        // No filter selected = show all threads
        if (!filters || !filters.open && !filters.closed) {
            combinedFilteredThreads = threadObjectsWithKey;
        }

        // Remove duplicate threads because some threads can appear under multiple filters, e.g. "heated" and "open".
        const threadsStringified = combinedFilteredThreads.map((thread) => JSON.stringify(thread));
        const threadsStringifiedWithoutDupes = threadsStringified.filter(
            (stringifiedThread, idx) => threadsStringified.indexOf(stringifiedThread) === idx
        );
        combinedFilteredThreads = threadsStringifiedWithoutDupes.map((stringifiedThread) =>
            JSON.parse(stringifiedThread)
        );

        // Sorting threads

        // Sort by time and filter out threads older than or equal to the timestamp
        const newSortedThreads = combinedFilteredThreads
            .filter((thread) => (sortOrder === "newest" ? thread.time <= time : thread.time >= time))
            .sort((a, b) => {
                if (sortOrder === "newest") {
                    // Sort newest first
                    return b.time - a.time;
                } else if (sortOrder === "oldest") {
                    // Sort oldest first
                    return a.time - b.time;
                }
                // Fallback (no sorting)
                return 0;
            });
        setRemainingThreadCount(newSortedThreads.length - count);
        setSortedThreads(newSortedThreads.slice(0, count));
    }, [questionThreads, time, count, filters]);

    useEffect(() => {
        setTime(sortOrder === "newest" ? Date.now() : 0);
    }, [sortOrder]);

    return {
        allThreads: allThreads,
        threads: sortedThreads,
        remainingThreadCount: remainingThreadCount,
        newThreadCount: newThreadCount
    }
};

export function ModDashboardThreadsOverview() {
    const datastore = useDatastore();
    const [sortOrder, setSortOrder] = useState("oldest");
    const [loadAmount, setLoadAmount] = useState(20);
    const [loadTimestamp, setLoadTimestamp] = useState(Date.now());
    const [selectedFilters, setSelectedFilters] = useState({ open: false, closed: false });
    const params = useInstanceParams();

    const sortOrderOptions = [
        { key: "oldest", label: "Oldest" },
        { key: "newest", label: "Newest" },
    ];

    const { threads, allThreads, newThreadCount, remainingThreadCount } = useModerationThreads({
        timestamp: loadTimestamp,
        sortOrder: sortOrder,
        count: loadAmount,
        filters: selectedFilters,
    });

    const activeThreadsCount = allThreads?.filter((thread) => !thread.readonly).length;
    const inactiveThreadsCount = allThreads?.filter((thread) => thread.readonly).length;

    const authorIds = useMemo(() => {
        return threads?.map(thread => thread.from) || [];
    }, [threads]);
    const authors = useModerationAuthorsByIds(authorIds);

    const loadMoreThreads = () => {
        setLoadAmount(loadAmount + 20)
    }

    const loadNewThreads = () => {
        setLoadAmount(loadAmount + newThreadCount)
        setLoadTimestamp(Date.now())
    }

    const recalculateHeated = async () => {
        if (threads) {
            const threadKeys = threads.map((thread) => thread.key)
            await datastore.callServerAsync("moderationZdf", "calculateHeated", { threadKeys: threadKeys })
        }
    }

    useEffect(() => {
        recalculateHeated()
    }, [threads])

    const renderedContent = (
        <>
            {threads && params?.threadId && (
                <ParentDatastoreProvider>
                    <EmbeddedInstance
                        structureKey={"question"}
                        instanceKey={params.threadId}
                        screenKey={"moderationDetails"}
                        features={{enablemoddashboardthreaddetailview: true}}
                    >
                        <ModDashboardThreadDetails
                            thread={allThreads[params.threadId]}
                            threadKey={params.threadId}
                        />
                    </EmbeddedInstance>
                </ParentDatastoreProvider>
            )}
            {threads && !params?.threadId && (
                <View style={{ backgroundColor: colorGreyPopupBackground, flexGrow: 1, flexShrink: 1 }}>
                    <PadBox vert={80}>
                        <Heading level={1} label={"Threads"} />
                        <Pad size={26} />
                        <SpacedArray pad={8} horiz>
                            <REPLACE_ZDF_FilterButton
                                selected={selectedFilters.open}
                                label={"Open Threads"}
                                count={activeThreadsCount || "0"}
                                countColor={colorTextGrey}
                                onPress={() => {
                                    selectedFilters.open
                                        ? setSelectedFilters({ ...selectedFilters, open: false })
                                        : setSelectedFilters({ ...selectedFilters, open: true });
                                }}
                            />

                            <REPLACE_ZDF_FilterButton
                                selected={selectedFilters.closed}
                                label={"Closed Threads"}
                                count={inactiveThreadsCount || "0"}
                                countColor={colorTextGrey}
                                onPress={() => {
                                    selectedFilters.closed
                                        ? setSelectedFilters({ ...selectedFilters, closed: false })
                                        : setSelectedFilters({ ...selectedFilters, closed: true });
                                }}
                            />
                        </SpacedArray>
                        <View>
                            <Pad size={26} />
                            <DropDownSelector
                                options={sortOrderOptions}
                                value={sortOrder}
                                onChange={setSortOrder}
                            />
                            <Pad size={16} />
                            <ModDashboardThreadsOverviewList
                                threads={threads}
                                authors={authors}
                                newThreadCount={newThreadCount}
                                remainingThreadCount={remainingThreadCount}
                                onLoadMore={loadMoreThreads}
                                onLoadNew={loadNewThreads}
                            />
                        </View>
                    </PadBox>
                </View>
            )}
            {!threads && <LoadingScreen />}
        </>
    );

    return <>
        {renderedContent}
    </>
}

export function ModDashboardThreadsOverviewList({
    threads,
    authors,
    newThreadCount = 0,
    remainingThreadCount = 0,
    onLoadMore,
    onLoadNew,
}) {
    const datastore = useDatastore();

    const params = useInstanceParams();
    const instanceKey = useInstanceKey();

    const openThreadDetails = (threadId) => {
        params.threadId = threadId;
        gotoInstance({structureKey: "moddashboard", instanceKey, params: params})
    };

    const loadMoreItems = () => {
        onLoadMore && onLoadMore();
    };

    return (
        <View style={{ gap: 16 }}>
            {threads ? (
                <View style={{ gap: 16 }}>
                    {threads.map((thread, i) => (
                        <ModDashboardThreadCard
                            author={authors?.[thread?.from]}
                            thread={thread}
                            key={i.toString() + thread.question}
                            onPressCard={() => {
                                openThreadDetails(thread.key);
                            }}
                        />
                    ))}
                    {remainingThreadCount > 0 && (
                        <Center>
                            <CTAButton label={"Load more"} onPress={loadMoreItems}></CTAButton>
                        </Center>
                    )}
                </View>
            ) : (
                <LoadingScreen />
            )}
        </View>
    );
}

export function ModDashboardThreadCard({ thread, secondaryTags=[], author, onPressCard}) {

    const threadIsClosed = thread?.readonly ? true : false
    const isHeated = thread.heated || false

    const s = ThreadCardStyle;

    return <HoverView onPress={onPressCard}>
        <View style={s.cardContainer}>
            <HorizBox spread>
                <View style={s.tagRow}>
                    <Tag compact label={threadIsClosed ? "Closed" : "Open"} color={threadIsClosed ? colorRedBackground : colorLightGreen} />
                    {secondaryTags.map((tag, i) => <Tag key={tag} compact label={tag} />)}
                </View>
                <View style={{ display: "flex", flexDirection: "row-reverse" }}>
                    {isHeated && <Tag compact label={"🔥 Heated"} color={"#FCEBEA"} />}
                </View>
            </HorizBox>
            <View style={s.mainContentRow}>
                <View style={s.titleBlock}>
                    <Paragraph text={thread.question} strong />
                    <UtilityText type="tiny" color={colorTextGrey} text={author?.name} />
                </View>
            </View>
            <ModDashboardThreadMetaData thread={thread} threadKey={thread.key} userId={author?.from} />
        </View>
    </HoverView>
}

const ThreadCardStyle = StyleSheet.create({
    cardContainer: {
        borderRadius: 10,
        padding: 16,
        gap: 16,
        backgroundColor: colorWhite,
        boxShadow: " 0px 2px 10px rgba(0, 0, 0, 0.1)"
    },
    tagRow: {
        flexDirection: "row",
        gap: 12
    },
    image: {
        width: 56,
        height: 56
    },
    mainContentRow: {
        flexDirection: "row",
        gap: 12,
        alignItems: "center"
    },
    titleBlock: {
        gap: 4
    },
    detailRow: {
        gap: 16,
        flexDirection: "row"
    },
    detailItem: {
        gap: 4,
        flexDirection: "row"
    }
})


export function ModDashboardThreadDetails() {
    const datastore = useDatastore();
    const threadKey = useInstanceKey();
    const thread = useModulePublicData("question", [threadKey])
    const threadAuthor = usePersonaObject(thread?.from)

    const articles = useCollection("backlink_article")

    if (!thread) {
        return <LoadingScreen />
    }

    return <PadBox top={58}>
        <SpacedArray pad={8}>
            <Heading text={thread?.question} level={1} />
            <UtilityText type="tiny" color={colorTextGrey} label={"Author: {author}"} formatParams={{author: threadAuthor?.name}}/>
        </SpacedArray>
        <Pad size={24} />
        <ModDashboardThreadMetaData thread={thread} threadKey={threadKey} articles={articles} />
        <Pad size={56} />
        <ModDashboardThreadDetailTabs thread={thread} threadKey={threadKey} />
    </PadBox>

}

function ModDashboardThreadMetaData({ thread, threadKey, articles }) {
    const commentCount = thread.commentCount
    const awaitingDecisionCount = useModulePublicData("moderation",["thread_queue_count",threadKey,"awaiting_decision_count"])
    const [display, shouldDisplay] = useState(false)
    const s = ThreadCardStyle;

    return <View style={s.detailRow}>
        {thread.time && <View style={s.detailItem}>
            <Time />
            <HorizBox>
                <UtilityText type="tiny" text={monthAbbreviations[new Date(thread.time).getMonth()]} color={colorTextGrey} />
                <UtilityText type="tiny" text={" " + new Date(thread.time).getDate()} color={colorTextGrey} />
            </HorizBox>

        </View>}
        {commentCount !== null && <View style={s.detailItem}>
            <Chat />
            <UtilityText type="tiny" text={commentCount?.toString()} color={colorTextGrey} />
        </View>}
        {thread.id && <View style={s.detailItem}>
            <IdManagement />
            <UtilityText type="tiny" text={thread.id} color={colorTextGrey} />
        </View>}
        {awaitingDecisionCount && <View style={s.detailItem}>
            <Flag color="red" />
            <UtilityText type="tiny" text={awaitingDecisionCount} color={colorRed} />
        </View>
        }
        {articles && <View style={s.detailItem}>
            <Link color={colorTextBlue} />
            <TextButton te formatParams={{numArticles:articles.length}} type="tiny" color={colorTextBlue} label={"{numArticles} linked stories"} onPress={() => shouldDisplay(true)} />
            {display && articles.length > 0 && <ModDashboardThreadArticleModal articles={articles} onClose={() => shouldDisplay(false)} />}
        </View>}
    </View>
}

export function ModDashboardThreadArticleModal({ onClose, articles }) {
    return <Modal onClose={onClose}>
        <View style={{ paddingVertical: 40, paddingHorizontal: 20, gap: 8 }}>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                <UtilityText type="small" strong label={"Linked stories "} />
                <PadBox horiz={4} />
                <View style={{ width: 25, height: 25, borderRadius: "50%", backgroundColor: "#E9E9E9", justifyContent: "center", alignItems: "center" }}>
                    <UtilityText text={articles.length} center strong />
                </View>
            </View>
            <Pad />
            <View style={{gap:8}}>
                {articles.map((article, key) => <ModDashboardThreadArticleCard key={key} article={article} />)}
            </View>
        </View>
    </Modal>
}

const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
]

function ModDashboardThreadArticleCard({ article = {} }) {
    const s = ArticleCardThreadStyle;

    return <View style={s.outer}>
        <View style={{ display: "flex", justifyContent: "flex-start" }}>
            <HorizBox center>
                <Image style={s.image} source={article.image} />
                <PadBox horiz={4} />
                <View style={s.articleList}>
                    <Paragraph text={article.title} color={colorTextGrey} />
                    <REPLACE_ZDF_LinkText label='Read article' type='small' url={keyToUrl(article.key)} testID={`Read ${article.title}`} strong />
                </View>
            </HorizBox>
        </View>
    </View>
}

const ArticleCardThreadStyle = StyleSheet.create({
    outer: {
        backgroundColor: "#F7F7F7", 
        borderRadius: 8, 
        padding: 12 
    },
    articleList: {
        gap: 4, 
        flexShrink: 1 
    },
    image: {
        width: 48,
        height: 48,
        borderRadius: 8
    }
})

function ModDashboardThreadDetailTabs({ thread, threadKey }) {
    const tabs = useMemo(
        () => [
            {
                id: "0",
                label: "Comments",
                component: () => (
                    <View>
                        <ModDashboardQueue
                            structureKey={"question"}
                            instanceKey={threadKey}
                            useAllFilteredQueues={useFilteredQueues}
                            useQueueCounts={getQueueSubsetCounts}
                        />
                    </View>
                ),
            },
        ],
        [thread.key]
    );

    return (
        <View style={{flexGrow: 1 }}>
            <TabNavigation initialTabId={0} tabs={tabs}>
                <TabBar />
                <TabContent />
            </TabNavigation>
        </View>
    );
}