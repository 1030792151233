
export const hydrangea3 = '#F5F9FF';
export const grey6 = '#2B2B2B';

export const colorWhite = '#ffffff';
export const colorBlack = '#000000';
export const colorNearBlack = '#0f0f0f';

export const colorTextGrey = '#65676B';
export const colorDisabledBackground = '#E9E9E9';
export const colorGreyBorder = '#D9D9D9';
export const colorDisabledText = '#818181'
export const colorTextBlue = '#5A1EF5';
export const colorGreyPopupBackground = '#F7F7F7';
export const colorTeaserBackground = '#F5F9FF';
export const colorTealBackground = '#058287';
export const colorMintGreenBackground = '#91D7BE';
export const colorSurfaceDiscovery = '#F6F0EC';

export const colorBannerGreen = '#B9EBDC';

export const colorAccent = '#5A1EF5';

export const colorBlackHover = '#2B2B2B';
export const colorGreyHover = '#E9E9E9';
export const colorAccentHover = '#4718C4';
export const colorGreyHoverBorder = '#0f0f0f';

export const colorGreyFormHover ='#f2f2f2';
export const colorGreyFormPress = '#e5e5e5';


export const colorPrimaryPress = '#6f6f6f';
export const colorSecondaryPress = '#cccccc';
export const colorAccentPress = '#361293';
export const colorIconButtonPress = '#bababa';

export const colorPink = '#FCEBEA';
export const colorPinkHover = '#E4D3D4';
export const colorPinkPress = '#CABCBB';
export const colorRed = '#F53C19';
export const colorGreen = '#00af87';
export const colorLightGreen = '#B9EBDC';
export const colorPurpleBackground = '#ECECFF';
export const colorPurplishBlue = '#525DCF';

export const colorBlueBackground = '#CDE1FF';
export const colorLightBlueBackground = '#F5F9FF';
export const colorPinkBackground = '#FEF7F2';
export const colorRedBackground = '#FDEBEA';

export const colorDataVizRegular = '#DCE0E5';
export const colorDataVizStrong = '#7265FF';

export const colorSecondaryJasper = '#EDE4DE';

// Default avatar colors
export const jasper1 = '#7d6453';
export const garnet1 = '#763a35';
export const garnet2 = '#b57a75';
export const beetle = '#a95851';
export const carnation1 = '#c34646';
export const kilauea1 = '#F53C19';
export const elsinore1 = '#f56400';
export const saguaro1 = '#a57328';
export const hoh1 = '#058287';
export const pisgah1 = '#553cd7';
export const timucuan1 = '#be73cd';
export const tanzanite1 = '#3f3f80';
export const tanzanite2 = '#7f7fc0';
export const emperor = '#525dcf';
export const lavender = '#7d7dff';
export const defaultAvatarColors = [jasper1, garnet1, garnet2, beetle, carnation1, kilauea1, elsinore1, saguaro1, hoh1, pisgah1, timucuan1, tanzanite1, tanzanite2, emperor, lavender];
