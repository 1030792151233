import {Center, Pad, PadBox} from "component/basics.js"
import {REPLACE_CBC_PopularComments} from "../../../component/cbcrc/comments";
import {ActionEdit, ActionReply, ActionReport, CommentsInput, ComposerScreen, BasicComments} from "component/comment.js"
import {Heading, Paragraph} from "component/text.js"
import {Catcher} from "system/catcher.js"
import {useCollection, useDatastore, useGlobalProperty} from "util/datastore.js"
import {CTAButton} from "component/button.js"
import {colorTextGrey} from "component/color.js"
import { spacings } from "component/constants";

export const TopicUserQuestionsFeature = {
    key: 'topicuserquestions',
    name: 'Community Questions',
    config: {
    },
    subscreens: {
        allQuestions: () => <AllQuestions />,
        composer: ({commentKey, about}) => <ComposerScreen about={about} commentKey={commentKey} intro={<QuestionHeader />} contentType='Public Response' />,
    },
    defaultConfig: {
        commentActions: [ActionReply],
        commentRightActions: [ActionReport, ActionEdit],
        commentTopWidgets: [],
        commentAboveWidgets: [],
        replyAboveWidgets: [],
        commentAllowEmpty: false,
        commentEditBottomWidgets: [],
        commentEditTopWidgets: [],
        commentPostBlockers: [],
        commentInputPlaceholder: 'Ask us a question...',
        commentReplyPlaceholder: 'Reply to {authorName}...',
        commentInputLoginAction: 'answer',
        commentPostCheckers: [],
        commentFilters: [],
        commentRankers: [],
        replyFilters: [],
        pageTopWidgets: [],
        topBanners: [],
        composerTopBanners: [],
        composerSubtitle: () => 'Public response',
        poll: null,
        noCommentsMessage: 'Be the first to share your thoughts.',
        noMoreCommentsMessage: 'No more questions',
        PollWidget: null,
        visualizationType: 'percent',
        teaser: null,
        adminScreen: null,
    },
}

export const TopicUserQuestionCallToActionFeature = {
    key: 'topicuserctaquestions',
    name: 'Community Questions Call to Action',
    config: {
        topicContentWidgets: [TopicCallToAction]
    },
}
export const TopicPopularQuestionsFeature = {
    key: 'topicpopularquestions',
    name: 'Popular Questions',
    config: {
        topicContentWidgets: [TopicQuestions]
    },
}

function TopicCallToAction() {
    const conversationStarter = useGlobalProperty('conversationStarter');

    return <PadBox top={spacings.xl2} horiz={spacings.lg}>
        <Heading type="large" weight="medium" text={conversationStarter} />
        <Pad size={spacings.xl} />
        <CommentsInput />
        <Pad size={spacings.sm} />
        <Paragraph color={colorTextGrey} label='Your question will be considered for future content pieces. Please note, we may not be able to answer all questions.' />
    </PadBox>
}

function TopicQuestions() {
    const datastore = useDatastore();
    const comments = useCollection('comment', {filter: {about: null, replyTo: null}, sortBy: 'time'});

    return <PadBox vert={spacings.xl2} horiz={spacings.lg}>

        <Heading label='Popular questions' />
        <Catcher>
            <REPLACE_CBC_PopularComments canPost={false} />
        </Catcher>
        {comments?.length > 4 && (
            <>
                <Pad size={spacings.lg} />
                <Center>
                    <CTAButton type='primary' label='View all questions'
                        onPress={datastore.needsLogin(() => datastore.pushSubscreen('allQuestions', {}))} />
                </Center>
            </>
        )}
    </PadBox>
}


function QuestionHeader() {
    const conversationStarter = useGlobalProperty('conversationStarter');

    return <PadBox horiz={spacings.lg} vert={spacings.xl2}>
        <Heading type="large" weight="medium" text={conversationStarter} />
    </PadBox>
}

function AllQuestions() {
    return <PadBox horiz={spacings.lg} vert={spacings.xl2}>
        <Catcher>
            <BasicComments canPost={false} />
        </Catcher>
    </PadBox>
}
