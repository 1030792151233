import React from "react";
import { View } from "react-native";
import { useCollection, useDatastore } from "util/datastore.js"
import { useConfig } from "util/features.js"
import { HorizBox, PadBox } from "component/basics.js"
import { Catcher, CatchList } from "system/catcher.js"
import { colorTeaserBackground, colorLightBlueBackground } from "component/color.js"
import { Comment } from "component/comment.js"
import { RichText } from "component/richtext.js"
import { Banner } from "component/banner.js"

export function REPLACE_CBC_PopularComments({about=null, showInput=true, canPost=true}) {
    const {noCommentsMessage} = useConfig();
    const comments = useCollection('comment', {filter: {about, replyTo: null}, sortBy: 'time', reverse: true})
    const upVotes = useCollection('upvote');

    const topComments = comments.filter((comment) => upVotes.some((upVote) => upVote.comment === comment.key));
    const otherComments = comments.filter((comment) => !upVotes.some((upVote) => upVote.comment === comment.key));
    const popularComments = [...topComments, ...otherComments].slice(0,4);

    return (
        <View>
            {comments?.length == 0 &&
                <PadBox vert={20} horiz={20}><Banner color={colorTeaserBackground}><RichText label={noCommentsMessage} /></Banner></PadBox>
            }
            <CatchList items={popularComments} renderItem={comment => <Comment commentKey={comment.key} />} />
        </View>
    )
}