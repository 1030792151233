import { Banner } from "component/banner.js"
import { Pad, PadBox, HorizBox } from "component/basics.js"
import { CTAButton, SubtleButton, TextButton } from "component/button.js"
import { colorLightBlueBackground, colorPink, colorBlack, colorBlackHover, colorGreyBorder, colorTextGrey } from "component/color.js"
import { RichText } from "component/richtext.js"
import { Heading, TextField, UtilityText } from "component/text.js"
import { useDatastore, useSessionData } from "util/datastore.js"
import { useState } from "react";
import { Modal } from "component/modal.js"
import { goBack } from "util/navigate.js"
import { logEventAsync, useLogEvent } from "util/eventlog.js"
import { GUIDELINES, PARENT_GUIDELINES, getParentRuleByChildText, getRuleById, GuidelinesModal } from "../question/CommunityGuidelinesFeature";
import { View, StyleSheet } from "react-native";
import { Pin, Send } from "@carbon/icons-react";
import { formatDate } from "component/date.js"
import React from "react";
import { getWillHelpBeSeenAsync, HelpBubble } from "component/help.js"
import { sleepMillisAsync } from "util/util.js"

export const PreModeratorFeature = {
    name: 'Pre Moderation',
    key: 'premoderation',
    config: {
        commentPostBlockers: [isPostButtonDisabledAsync],
        commentEditBottomWidgets: [ModerationReport],
        commentPostCheckers: [checkPostAsync],
        topBarHelpBubbles: [ModerationInProgessHelpBubble]
    },
    defaultConfig: {
        commentModerators: [],
        useOnlyGptModeration: false
    }
}

export const ModerationHelpFeature = {
    name: 'Moderation Toast',
    key: 'moderationhelp',
    dev: true,
    config: {
        topBarHelpBubbles: [ModerationInProgessHelpBubble],
        commentPostCheckers: [waitForHelpBubbleAsync]
    }
}

export const ModerateWithGPTFeature = {
    name: 'GPT',
    key: 'aimoderation',
    config: {
        commentModerators: [moderateWithGPT]
    }
}

export const ModerateWithJigsawFeature = {
    name: 'Jigsaw',
    key: 'jigsawmoderation',
    config: {
        commentModerators: [moderateWithJigsaw]
    }
}

async function moderateWithGPT({datastore, comment, questionText}) {
    const moderationResult = await datastore.callServerAsync(
        'moderation', 'checkCommentWithGpt', {commentText: comment.text, questionText});
    if (moderationResult.violates) {
        const violations = moderationResult.rulesBroken?.map(getRuleById) ?? [];
        
        return {
            violates: moderationResult.violates, violations, source: 'ai',
            metadata: {rulesBroken: JSON.stringify(moderationResult.rulesBroken ?? [])}
        };
    } else {
        return {violates: false}
    }
}

async function moderateWithJigsaw({datastore, comment}) {
    const moderationResult = await datastore.callServerAsync(
        'moderation', 'checkCommentWithJigsaw', {text: comment.text});
    if (moderationResult.violates) {
        return {
            violates: true, source: 'jigsaw', 
            violations: [GUIDELINES.NO_UNCIVIL],
            metadata: {toxicity: moderationResult.toxicity}
        };
    } else {
        return {violates: false}
    }
}

export const ModerateRepliesStrictlyFeature = {
    name: 'Strict Reply Moderation',
    key: 'strictreplymoderation',
    config: {
        commentModerators: [moderateRepliesStrictly]
    }
}


async function moderateRepliesStrictly({datastore, comment}) {
    if (!comment.replyTo) {
        return {violates: false}
    }
    const parentComment = await datastore.getObject('comment', comment.replyTo);
    const moderationResult = await datastore.callServerAsync('moderation', 'moderateReplyStrictly', {
        parentText: parentComment.text, text: comment.text, 
        commentKey: comment.key || null, inReview: comment.inReview
    });
    if (!moderationResult.allow) {
        return {violates: true, source: 'ai-reply-moderaton',
            violations: [GUIDELINES.BE_CONSTRUCTIVE]
        }
    } else {
        return {violates: false}
    }
}


async function checkPostAsync({datastore, comment, questionText}) {
    const {commentModerators} = datastore.getConfig();

    if (!comment.text?.trim()) {
        return {allow: true, commentProps: {violates: false, inReview: false}};
    }

    var violation = null;
    var violations = [];

    for (const moderator of (commentModerators || [])) {
        const moderationResult = await moderator({datastore, comment, questionText});
        if (moderationResult.violates) {
            violations = [...violations, ...moderationResult.violations];
            violation = moderationResult;
            logEventAsync(datastore, 'moderation-block', {text: comment.text});
            break;
        }
    }
    violations = [...new Set(violations)];
    const violates = violations.length > 0;
    const violatingTextChanged = comment.violatingText && (comment.text !== comment.violatingText);

    if (comment.inReview && (!violates || violatingTextChanged)) {
        await datastore.callServerAsync('moderation', 'removeCommentFromReviewQueue', {commentKey: comment.key});
        await datastore.updateObject("comment", comment.key, { ...comment, inReview: false });
    }

    return {
        allow: !violates, commentProps: {
            violates, inReview: false, violations,
            violatingText: violates ? comment.text : null,
        }
    };
}

export async function checkPerspectiveAsync({datastore, perspectiveText, questionText, language='English'}) {
    const moderationResult = await datastore.callServerAsync(
        'moderation', 'checkPerspectiveWithGpt', {perspectiveText, questionText, language});
    if (moderationResult.violates) {
        const violations = moderationResult.rulesBroken?.map(getRuleById) ?? [];
        return {violates: true, violations: [...new Set(violations)]}
    } else {
        return {violates: false}
    }
}

function ModerationInProgessHelpBubble() {
    const moderationInProgress = useSessionData('moderationInProgress');
    if (!moderationInProgress) { return null; }
    return <HelpBubble right label='Your response is being reviewed by AI to ensure it follows our Community Guidelines' 
        condition={moderationInProgress} helpKey='moderationInProgress'
    />
}

async function waitForHelpBubbleAsync({datastore, comment}) {
    const helpSeen = await getWillHelpBeSeenAsync({datastore, helpKey: 'moderationInProgress'});
    if (comment.replyTo && !helpSeen) return {allow: true};
    datastore.setSessionData('moderationInProgress', true);
    await sleepMillisAsync(4000);
    datastore.setSessionData('moderationInProgress', false);
    return {allow: true}; 
}




function isPostButtonDisabledAsync({datastore, comment}) {
    return comment.violates && comment.violatingText == comment.text;
}

export function ModerationReport({comment, onCancel, isPerspectiveModeration=false}) {
    const s = moderationReportStyles;
    const [showModerationModal, setShowModerationModal] = useState(false);
    const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(false);
    const target = isPerspectiveModeration ? comment?.perspectives?.writeIn : comment;
    const parentViolations = Array.from(new Set(target?.violations?.map(getParentRuleByChildText) ?? []));

    if (!target.violates) {
        return null;
    } else {
        return <View style={s.container}>
            <PadBox horiz={20} vert={16}>
                <Heading label={'⚠️ Check your ' + (isPerspectiveModeration ? 'perspective' : 'response')}/>
                <Pad size={16} />
                <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                    <UtilityText label={'AI review found that your ' + (isPerspectiveModeration ? 'perspective label' : 'response') + ' may be incompatible with the following '}/>
                    <TextButton type="small" label='Community Guidelines' color={colorBlack} underline onPress={() => setShowCommunityGuidelines(true)} />
                    <UtilityText text=": " />
                    {parentViolations.map((parentViolation, i) => {
                        return <View key={i} style={{ flexDirection: 'row' }}>
                            <UtilityText weight='medium' label={parentViolation} />
                            {i < parentViolations.length - 1 && <UtilityText text=", " />}
                        </View>
                    })}
                </View>
                <Pad size={16} />
                <UtilityText label={'Consider rewording your ' + (isPerspectiveModeration ? 'perspective' : 'response') + ':'} />
                <Pad size={8} />
                {target.violations?.map((ruleText, i) => 
                    <BulletText key={i} label={ruleText} />
                )}
                <Pad size={20} />
                {!isPerspectiveModeration && (comment.inReview
                    ? <View style={{flexDirection: 'row'}}>
                        {React.createElement(Send, { style: { color: colorBlackHover } })}
                        <PadBox right={4}/>
                        <UtilityText color={colorBlackHover} type='tiny' label='Sent to moderator: {time}' formatParams={{time: formatDate(comment.time)}}/>
                    </View>
                    : <View>
                        <View style={s.line} />
                        <Pad size={20} />
                        <UtilityText weight='medium' label='Did AI get it wrong?' />
                        <Pad size={12} />                        
                        <SubtleButton type='tiny' icon={Send} color={colorBlackHover} label='Send to a moderator for review' onPress={() => setShowModerationModal(true)}/>
                    </View>
                )}
            </PadBox>
            {showModerationModal && <ModerationModal comment={comment} onCancel={onCancel} onClose={() => setShowModerationModal(false)} />}
            {showCommunityGuidelines && <GuidelinesModal onClose={() => setShowCommunityGuidelines(false)}/>}
        </View>
    }
}
const moderationReportStyles = StyleSheet.create({
    container: {
        backgroundColor: colorPink,
    },
    line: {
        height: 1, 
        backgroundColor: colorGreyBorder,
        width: '100%',
    },
});

function BulletText({label, text}) {
    return <HorizBox>
        <UtilityText text='•' />
        <Pad size={10}/>
        <UtilityText label={label} text={text} />
    </HorizBox>
}

// TODO: Bring back link to transparency dashboard, once we have cross-partner alignment
// and it works better

export function ModerationModal({comment, onClose, onCancel}) {
    const [inProgress, setInProgress] = useState(false);
    const [sent, setSent] = useState(false);
    const [appealText, setAppealText] = useState('');
    const datastore = useDatastore();
    const siloKey = datastore.getSiloKey();
    useLogEvent('moderation-modal', {text: comment.text});
    async function onReport() {
        setInProgress(true);
        const key = await datastore.callServerAsync('moderation', 'sendToReview', {
            type: 'comment', setInReview: true, source: 'premoderation',
            text: comment.text, key: comment.key, from: comment.from,
            object: comment,
            violations: JSON.stringify(comment.violations), appealText
        });
        
        // Run derived view triggers as comment gets updated serverside
        if (key) {
            await datastore.callServerAsync('derivedviews', 'runTriggers', {
                type: 'comment', key
            });
        }

        setInProgress(false);
        setSent(true);
        logEventAsync(datastore, 'moderation-appeal', {text: appealText});
    }

    function onDone() {
        onClose();
        if (onCancel) {
            onCancel();
        } else {
            goBack();
        }
    }

    const dashboardUrl = 'https://np-psi-dev.web.app/' + siloKey + '/moderationdash/dash';

    if (sent) {
        return <Modal onClose={onDone}>
            <PadBox horiz={20} vert={20}>
                <Heading type="large" weight="medium" label='Thank you!'/>
                <Pad size={8} />
                <UtilityText type='large' color={colorTextGrey} label='Your response has been sent to a moderator for review.' />
                <Pad size={32} />
                    <Banner color={colorLightBlueBackground}>
                        <Heading label='📫 What happens next'/>
                        <Pad size={16} />
                        <UtilityText label='You will receive an email notification when your post is approved or rejected by moderators.'/>
                    </Banner>
                <Pad />
            </PadBox>  
        </Modal>
    } else {
        return <Modal onClose={onClose} buttonRow={
            <CTAButton disabled={inProgress || !appealText} wide type='primary' label={inProgress ? 'Sending...' : 'Send to moderator'} onPress={onReport}/>
        }>
            <PadBox horiz={20} vert={20}>
                <Heading type="large" weight="medium" label='Send to moderator'/>
                <Pad />
                <Banner color={colorLightBlueBackground}>
                    <Heading label='✨ We use AI for moderation'/>
                    <Pad size={32} />
                    <RichText label='AI helps us moderate comments, but sometimes it can make a mistake. If AI is preventing you from sharing a respectful opinion, send your comment to a moderator for review.'/>
                </Banner>
                <Pad size={32} />
                <RichText label="**Share details** (required)" type="small" color={colorBlack}/>
                <Pad size={12} />
                <TextField testID='appeal' big value={appealText} onChange={setAppealText} placeholder='Message to Moderator...'/>
                <Pad size={12} />
                <UtilityText type='small' label='Provide any details to help us determine if your post follows our Community Guidelines.' color={colorTextGrey} />
            </PadBox>  
        </Modal>
    }
}
