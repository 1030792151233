import { StructureDemo } from "util/instance.js"
import { defaultPersonaList } from "util/testpersonas"


const comments = [
    { key: 3, from: 'c', text: 'Why is Mothra the best?' },
    { key: 4, from: 'd', text: 'Why is Mechagodzilla the best?' },
    { key: 5, from: 'bb', text: 'Why is King Gidorah the best?' },
    { key: 6, from: 'f', text: 'Why is Rodan the worst?' },
    { key: 1, from: 'aa', text: 'Who is Godzilla?' },
    { key: 10, from: 'g', replyTo: 1, text: 'He is a very large lizard' },
    { key: 2, from: 'b', text: 'Why are Godzilla and Kong fightning?' },
    { key: 20, from: 'h', replyTo: 2, text: 'Because it is cool' },
    { key: 21, from: 'i', replyTo: 2, text: 'They are mortal enemies' },
    { key: 22, from: 'b', replyTo: 21, text: 'That is not true, they do not fight' },
]

const topicPoll = [
    { key: 1, from: 'c', value: { label: 'Neutre' } },
    { key: 2, from: 'c', value: { label: 'Neutre' } },
    { key: 3, from: 'c', value: { label: 'Négatif' } },
    { key: 4, from: 'c', value: { label: 'Négatif' } },
    { key: 5, from: 'c', value: { label: 'Négatif' } },
    { key: 6, from: 'c', value: { label: 'Négatif' } },
    { key: 7, from: 'c', value: { label: 'Très négatif' } },
    { key: 8, from: 'c', value: { label: 'Très négatif' } },
    { key: 9, from: 'c', value: { label: 'Très négatif' } },
    { key: 10, from: 'c', value: { label: 'Très négatif' } },
    { key: 11, from: 'c', value: { label: 'Très négatif' } },
    { key: 12, from: 'c', value: { label: 'Très négatif' } },
    { key: 13, from: 'c', value: { label: 'Très négatif' } },
]

const relatedConversations_derived = [
    { key: 'le-temps-dcran' },
    { key: 'le-vieillissement-de-la-population' },
    { key: 'le-vieillissement-de-la-population-2' },
]

const relatedConversations = [
    {
        deleted: false,
        from: "a",
        id: "le-temps-dcran",
        key: "le-temps-dcran",
        text: "Le temps d'écran",
        time: 1733775285001
    },
    {
        deleted: false,
        from: "b",
        id: "le-vieillissement-de-la-population",
        key: "le-vieillissement-de-la-population",
        text: "Le vieillissement de la population",
        time: 1733775279409
    },
    {
        deleted: false,
        from: "b",
        id: "le-vieillissement-de-la-population-2",
        key: "le-vieillissement-de-la-population-2",
        text: "Le vieillissement de la population, suivi",
        time: 1733775279409
    }
]

const globals = {
    name: 'L’intelligence artificielle et son incidence sur nos vies',
    status: 'live',
    startAt: '2024-06-01',
    endAt: '2025-06-30',
    conversationStarter: 'Des questions? Des craintes? Parlez d’IA avec nos spécialistes.',
    poll: {
        question: 'Comment voyez-vous la place grandissante que prend l’IA dans votre quotidien?',
        type: 'MultipleChoice',
        showDataviz: true,
        options: [{ label: 'Très positif' }, { label: 'Positif' }, { label: 'Neutre' }, { label: 'Négatif' }, { label: 'Très négatif' }],
    },
    relatedArticles: [
        {
            text: 'Le recours à l’IA en hausse dans la population canadienne',
            picture: 'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-premiere/16x9/mdr-robot-conversationnel.jpg',
            type: 'text',
            url: 'https://ici.radio-canada.ca/nouvelle/2048276/ia-canada-sondage-hausse-utilisation',
            id: 1,
        },
        {
            text: 'Google et la gestion de la circulation : la Ville espère des économies',
            picture: 'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/16x9/gestionnaire-arteriel.jpg',
            type: 'text',
            url: 'https://ici.radio-canada.ca/nouvelle/2046551/intelligence-artificielle-circulation-automobile-gestion-arterielle',
            id: 2,
        },
        {
            text: 'Des artistes poursuivent OpenAI, accusée d’avoir utilisé leurs livres pour ChatGPT',
            picture: 'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/16x9/ai-intelligence-artificielle-chatgpt.jpg',
            type: 'text',
            url: 'https://ici.radio-canada.ca/nouvelle/1995206/livres-chatgpt-openai-poursuite',
            id: 3,
        },
        {
            text: 'Prédire les complications du cancer grâces à L’IA',
            picture: 'https://images.radio-canada.ca/v1/ici-tele/16x9/decouverte-sante-ia-intelligence-artificielle-4-fevrier-2024.jpg',
            type: 'media',
            url: 'https://ici.radio-canada.ca/info/videos/1-8887402/predire-complications-cancer-graces-a-ia',
            id: 4,
        },
    ],
    testContents: [
        {
            id: '3ldrP0ctarks-test',
            text: "Prédire les complications du cancer grâce à l'IA",
            picture: 'https://placehold.co/250x150',
            type: 'media',
        },
    ],
    nextTopicPoll: {
        question: 'De quoi aimeriez-vous qu’on discute bientôt?',
        type: 'MultipleChoiceOther',
        showDataviz: false,
        otherOptionValue: 'Autre',
        otherOptionPlaceholder: 'Rédiger un sujet...',
        options: [{ label: 'Guerre en Ukraine' },
        { label: 'Élections américaines' },
        { label: "Inflation et pouvoir d'achat" },
        ],
    },
    titledWriter: {
        iconeUrl: 'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/perso/logo-radio-canada-pizza-rouge-archives.jpg',
    }
}

const allTopicFeatures = {
    amaheader: true,
    askmeanything: true,
    commentssort: true,
    editorialtopic: true,
    pin_answer: true,
    premoderator: false,
    titledWriter: true,
    topicfollow: true,
    topiclivestatus: false,
    topicparticipants: false,
    topicphototeaser_topic: true,
    topicpopularquestions: true,
    topicrelatedarticlescarousel: true,
    topicrelatedconversations: true,
    topictype: true,
    topicuserquestions: true,
    undefined: false,
    upvote: true,
    amapoll: true,
    
}

const relatedArticles = [
    {
        date: "2024-12-03T14:47:46.8...",
        deleted: false,
        id: "httpsiciradio-canadacanouvelle2123937oui-allo-fraude-arnaque-voix-deepfake",
        key: "httpsiciradio-canadacanouvelle2123937oui-allo-fraude-arnaque-voix-deepfake",
        picture: "https://images.radio-canada.ca/q_auto,w_1250/v1/ici-info/dossier/16x9/5489-fraude-2-facteurs-9124.jpg",
        published: true,
        text: "Fraude téléphonique avec oui allô",
        type: "text",
        url: "https://ici.radio-canada.ca/nouvelle/2123937/oui-allo-fraude-arnaque-voix-deepfake"
    },
    {
        date: "2024-12-03T14:47:46.8...",
        deleted: false,
        id: "httpsiciradio-canadacanouvelle2125173piratage-salt-typhoon-telephones-sms",
        key: "httpsiciradio-canadacanouvelle2125173piratage-salt-typhoon-telephones-sms",
        picture: "https://images.radio-canada.ca/q_auto,w_1250/v1/ici-info/16x9/surveillance-securite-donnees-cellulaire-espionnage-internet-mobile.jpg",
        published: true,
        text: "Piratage massif d’opérateurs téléphoniques américains",
        type: "text",
        url: "https://ici.radio-canada.ca/nouvelle/2125173/piratage-salt-typhoon-telephones-sms"
    },
    {
        date: "2024-12-03T14:47:46.8...",
        deleted: false,
        id: "httpsiciradio-canadacanouvelle2125643frontiere-canada-etats-unis-immigration",
        key: "httpsiciradio-canadacanouvelle2125643frontiere-canada-etats-unis-immigration",
        picture: "https://images.radio-canada.ca/q_auto,w_1250/v1/ici-info/transit/16x9/photo-radio-canada-ivanoh-demers-images-generiques-pour-illustrer-les-points-de-passages-a-la-frontiere-du-quebec-et-des-etats-unis-photo-prise-au-chemin-roxham-nichols-et-sweet-a-proximite-du-chemin-55335.jpg",
        published: true,
        text: "Frontière : Ottawa pourrait dépenser plus d’un milliard",
        type: "text",
        url: "https://ici.radio-canada.ca/nouvelle/2125643/frontiere-canada-etats-unis-immigration"
    }
]

export function AskMeAnythingDemo() {
    const collections = {
        comment: comments,
        topicPoll,
        relatedArticles: relatedArticles,
        relatedConversations: relatedConversations,
        derived_relatedQuestionPreview: relatedConversations_derived,
        persona: [...defaultPersonaList, {
            key: 'aa',
            name: 'John Doe',
            hue: 250,
            isAffiliatedToPSM: true,
            title: 'Comedian',
        },
        {
            key: 'bb',
            name: 'Jane Doe',
            hue: 250,
            isAffiliatedToPSM: true,
            title: 'Journalist',
        }],
    }
    return <StructureDemo testState={{roles: ['Editorial'], globals, collections, modulePublic: {language: 'french'}}} 
        features={allTopicFeatures}
        structureKey='topic' language="french"
    />
}