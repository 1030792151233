import React from 'react';
import { useIsAdmin } from "component/admin.js"
import { PadBox } from "component/basics.js"
import { TextButton } from "component/button.js"
import { UtilityText } from "component/text.js"
import { useConfig } from "util/features.js"
import { useServersideConstructor } from 'component/constructor.js'
import { useDatastore } from 'util/datastore.js'
import { FeatureToggles } from 'component/topbar.js'

export const ArticleStructure = {
    key: 'article',
    name: 'Article',
    screen: ArticleScreen,
    teaser: ArticleTeaser,
    subscreens: {
        admin: () => <ArticleAdminScreen />,
        features: () => <ArticleFeaturesScreen />
    },
    defaultConfig: {
        teaser: null,
        adminScreen: null
    }
}

export function ArticleScreen() {
    return <UtilityText label='No main screen defined.' />
}

export function ArticleTeaser(params) {
    useServersideConstructor();
    const datastore = useDatastore();
    const {teaser} = useConfig();
    const isAdmin = useIsAdmin();
    if (teaser) {
        return React.createElement(teaser, params);
    } else if (isAdmin) {
        return <TextButton label='Open admin screen' onPress={() => datastore.pushSubscreen('admin', {})} />
    } else {
        return <UtilityText label='No teaser defined' />
    }
}

export function ArticleAdminScreen() {
    const {adminScreen} = useConfig();
    if (adminScreen) {
        return React.createElement(adminScreen);
    } else {
        return <PadBox horiz={20} vert={20}><UtilityText label='No admin screen defined. Use the feature selector to enable a feature.' /></PadBox>
    }
}

export function ArticleFeaturesScreen() {
    return <PadBox horiz={20} vert={20}><FeatureToggles /></PadBox>
}
