import { UtilityText } from "component/text.js"
import { ArticleCard, QuestionCommentSection, QuestionScreen } from "../structure/question"
import { CLICK } from "system/demo.js"
import { fbKeyToString } from "util/firebase.js"

export const DemoQuestionBasicsFeature = {
    key: 'demo_question_basics',
    name: 'Demo - Question Basics',
    config: {
        componentSections: [
            {label: 'Conversation', key: 'coversation', pages: [
                {
                    label: 'Question Basics', key: 'questionbasics',
                    storySets: questionBasicsStorySets
                }
            ]},            
        ]
    }
} 

const collections = {
    comment: [
        {key: 1, from: 'a', text: 'People who disagree with me should be tortured.'},
        {key: 2, from: 'b', text: 'This is a comment'},
        {key: 3, from: 'c', text: 'This is another comment'},
        {key: 4, from: 'd', text: 'This is a forth comment'},
        {key: 5, from: 'e', text: 'This is a fifth comment'},
    ]
}


function questionBasicsStorySets() {return [
    {
        label: 'Embedded Question',
        collections,
        globals: {name: 'My Question'},
        content: <QuestionCommentSection />,
        structureKey: 'question',
        features: ['embedded']
    },
    {
        label: 'Pin Comment',
        collections: {comment: [{key: 1, from: 'a', text: 'This is a comment'}]},
        structureKey: 'question',
        features: ['pin_comment'],
        content: <QuestionCommentSection />,       
        roles: ['Moderator'], 
        serverCall: {derivedviews: {runTriggers: () => {}}},
        stories: [
            {label: 'Pin Comment', actions: [
                CLICK('Pin')
            ]}
        ]
    },
    {
        label: 'Article Card',
        collections: {backlink_article: [
            {key: 'a', title: 'My Article', url: 'https://www.google.com', image: 'https://via.placeholder.com/150', description: 'This is a description of my article', date: '2021-01-01'},
            {key: 'b', title: 'An article that has an annoyingly long title', url: 'https://www.google.com', image: 'https://via.placeholder.com/150',  date: '2024-01-01'}
        ]},
        content: <ArticleCard article={{
            key: decodeURIComponent(fbKeyToString('https://google.com')), title: 'My Article', image: 'https://via.placeholder.com/150', date: '2021-01-01'
        }} tags={['Climate', 'Monsters']} />
    }
]}