
export const githubLogin = {
    clientId: 'Ov23liYVNTv1E8unqe4c',
    authUrl: 'https://github.com/login/oauth/authorize',
    scope: 'read:user user:email',
    extraParams: {response_type: 'code'},
    mode: 'code',
    name: 'Github',
    key: 'github',
    icon: 'github.png',
    silos: ['global', 'demo'],
}

export const rcIntLogin = {
    key: 'rcInt',
    clientId: '77d1b085-1c39-4f0e-b6ce-92acaf992295',
    authUrl: 'https://int-login.cbc.radio-canada.ca/0aa1e8c2-5242-47a4-996a-6ed694ee7127/B2C_1A_ExternalClient_FrontEnd_Login/oauth2/v2.0/authorize',
    extraParams: {
        response_type: 'id_token',
        response_mode: 'fragment',
        redirect_uri: 'https://psi.zdf.digital/global/login/one/fragmentRedirect',
        scope: 'openid https://rcmnb2cint.onmicrosoft.com/1d0b826c-646a-472a-9d3a-fdf4441895e9/email https://rcmnb2cint.onmicrosoft.com/1d0b826c-646a-472a-9d3a-fdf4441895e9/profile',
        ui_locales: 'fr'
    },
    mode: 'fragment',
    name: 'Radio Canada Internal',
    silos: ['global', 'demo', 'test'],
    icon: 'rc.svg'
}

export const rcLogin = {
    key: 'rc',
    clientId: '32c46880-ae6e-429c-a437-97bab2c26f86',
    authUrl: 'https://login.cbc.radio-canada.ca/bef1b538-1950-4283-9b27-b096cbc18070/B2C_1A_ExternalClient_FrontEnd_Login/oauth2/v2.0/authorize',
    extraParams: {
        response_type: 'id_token',
        response_mode: 'fragment',
        redirect_uri: 'https://psi.zdf.digital/global/login/one/fragmentRedirect',
        scope: 'openid https://rcmnb2cprod.onmicrosoft.com/84593b65-0ef6-4a72-891c-d351ddd50aab/email https://rcmnb2cprod.onmicrosoft.com/84593b65-0ef6-4a72-891c-d351ddd50aab/profile',
        ui_locales: 'fr'
    },
    mode: 'fragment',
    name: 'Radio Canada',
    icon: 'rc.svg',
    silos: ['global', 'demo', 'test', 'rc'],
}

export const cbcIntLogin = {
    key: 'cbcInt',
    clientId: '9ff39f62-d5ba-4899-b24e-414f22c12d01',
    authUrl: 'https://int-login.cbc.radio-canada.ca/0aa1e8c2-5242-47a4-996a-6ed694ee7127/B2C_1A_ExternalClient_FrontEnd_Login/oauth2/v2.0/authorize',
    extraParams: {
        response_type: 'id_token',
        response_mode: 'fragment',
        redirect_uri: 'https://psi.zdf.digital/global/login/one/fragmentRedirect',
        scope: 'openid https://rcmnb2cint.onmicrosoft.com/1d0b826c-646a-472a-9d3a-fdf4441895e9/email https://rcmnb2cint.onmicrosoft.com/1d0b826c-646a-472a-9d3a-fdf4441895e9/profile',
        ui_locales: 'en'
    },
    mode: 'fragment',
    name: 'CBC Internal',
    silos: ['global', 'demo', 'test', 'cbc'],
    icon: 'rc.svg'
}

export const cbcLogin = {
    key: 'cbc',
    clientId: '696644d6-036c-41d7-9983-ef45763d1648',
    authUrl: 'https://login.cbc.radio-canada.ca/bef1b538-1950-4283-9b27-b096cbc18070/B2C_1A_ExternalClient_FrontEnd_Login/oauth2/v2.0/authorize',
    extraParams: {
        response_type: 'id_token',
        response_mode: 'fragment',
        redirect_uri: 'https://psi.zdf.digital/global/login/one/fragmentRedirect',
        scope: 'openid https://rcmnb2cprod.onmicrosoft.com/84593b65-0ef6-4a72-891c-d351ddd50aab/email https://rcmnb2cprod.onmicrosoft.com/84593b65-0ef6-4a72-891c-d351ddd50aab/profile',
        ui_locales: 'en'
    },
    mode: 'fragment',
    name: 'CBC',
    icon: 'rc.svg',
    silos: ['global', 'demo', 'test', 'cbc'],
}

export const zdfIntLogin = {
    key: 'zdfInt',
    clientId: "PSI-Testsystem",
    authUrl: "https://keycloak.psi.zdf.digital/realms/PSI/protocol/openid-connect/auth",
    extraParams: {
        response_type: 'id_token',
        response_mode: 'fragment',
        redirect_uri: "https://psi.zdf.digital/zdf/login/one/fragmentRedirect",
        scope: "openid email",
        grant_type: "client_credentials",
        kc_locale: "de"
    },
    mode: 'fragment',
    name: 'ZDF Internal',
    silos: ['global', 'demo', 'test', 'zdf'],
}

export const rtbfLogin = {
    key: 'rtbf',
    clientId: 'uSOpkEV_AOlqUda1ogf49UIn',
    authUrl: 'https://login.rtbf.be/oidc/op/v1.0/3_AqPTIGmFen0XApW69kOCpXuDvZD7uH26e5UNtKwwSCVwrGQabWexUophtXmuuv44/authorize',
    extraParams: {
        response_type: 'id_token',
        response_mode: 'fragment',
        redirect_uri: 'https://psi.zdf.digital/global/login/one/fragmentRedirect',
        scope: 'openid email',
    },
    mode: 'fragment',
    name: 'RTBF',
    icon: 'rtbf.svg',
    silos: ['global', 'demo', 'test', 'rtbf'],
}

export const silosWithoutGoogleLogin = ['rc']
