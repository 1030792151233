
export const ui_translations_german = {
    // NP Platform
    'Member': 'Mitglied',
    'Guest': 'Gast',
    '{count} {noun}': '{count} {noun}',
    '{count} {noun} {isAre} here': '{count} {noun} {isAre} sind hier',
    'Hello {name}': 'Hallo {name}',
    'comment': 'Kommentar',
    'comments': 'Kommentare',
    'person': 'Person',
    'people': 'Leute',
    'is': 'ist',
    'are': 'sind',
    'Like': "Gefällt mir",
    'Unlike': "Gefällt mir nicht",
    'Reply': 'Antworten',
    'Collapse': 'Einklappen',
    'No comments yet. Start the conversation!': 'Noch keine Kommentare. Beginne die Unterhaltung!',
    'Join the conversation by submitting a comment. Be sure to follow our [community guidelines](https://example.com).': 'Nimm an der Unterhaltung teil, indem du einen Kommentar abgibst. Stelle sicher, dass du unseren [Community-Richtlinien](https://example.com) folgst.',
    'Log in': 'Anmelden',
    'Comments': 'Kommentare',
    'Continue with Google': 'Mit Google fortfahren',
    'Log in to comment': 'Melde dich an, um zu kommentieren',
    'Public Comment': 'Öffentlicher Kommentar',
    'Upvote': 'Hochwählen',
    'Edit': 'Bearbeiten',
    'Cancel': 'Abbrechen',
    '{time}': '{time}',
    '{time} • Edited': '{time} • Bearbeitet',
    ' • {time}': ' • {time}',
    ' • {time} • Edited': ' • {time} • Bearbeitet',
    '{minutes} minutes ago': 'Vor {minutes} Minuten',
    '1 minute ago': 'Vor 1 Minute',
    '{hours} hours ago': 'Vor {hours} Stunden',
    '1 hour ago': 'Vor 1 Stunde',
    'Just now': 'Gerade jetzt',
    '1 day ago': 'Vor 1 Tag',
    '{days} days ago': 'Vor {days} Tagen',
    'now': 'jetzt',
    '{minutes}m': '{minutes}m',
    '{hours}h': '{hours}h',
    '{days}d': '{days}t',
    '{years}y': '{years}j',
    'Reply to {authorName}...': 'Antworte {authorName}...',
    'reply': 'antworten',
    'reaction': 'reaktion',
    'replies': 'antworten',
    'reactions': 'reaktionen',
    'Profile': 'Profil',
    'Account': 'Konto',
    'Log out': 'Abmelden',
    'Read more': 'Mehr lesen',
    'Once you log in, you can use a different display name if you prefer to not reveal your real name': 'Wenn du dich angemeldet hast, kannst du einen anderen Anzeigename verwenden, wenn du deinen echten Namen nicht angezeigt willst',
    'Once you log in, enter a display name': 'Wenn Du Dich einloggst, kannst Du Deinen Anzeigenamen auswählen.',
    'Add your display name': 'Anzeigename hinzufügen',
    'Set your visibility after log in': 'Setze deine Sichtbarkeit nach dem Anmelden',

    // Help
    'Start the conversation': 'Beginne die Unterhaltung',
    "Same question, new article?": "Anderer Inhalt, gleiche Frage?",
    "Update your previous response with fresh insights": "Aktualisiere deinen bisherigen Beitrag mit neuen Erkenntnissen.",

    // Feature module names
    'Upvote Comments': 'Kommentare hochwählen',
    'Comment Length Limit': 'Kommentarlängenbegrenzung',
    'Demo Moderation': 'Demo-Moderation',
    'Basic Teaser': 'Grundlegender Teaser',
    'Demo Filter': 'Demo-Filter',

    // Profile
    'Profile name': 'Profilname',
    'Your profile photo': 'Dein Profilfoto',
    'A pseudonym': 'Ein Pseudonym',
    'Enter a pseudonym': 'Gib ein Pseudonym ein',
    'This pseudonym may violate our community guidelines.': 'Dieses Pseudonym könnte gegen unsere Community-Richtlinien verstoßen.',
    'Pseudonyms are not allowed to look like real names.': 'Pseudonyme dürfen nicht wie echte Namen aussehen.',
    'This pseudonym is already in use. Please choose a different one.': 'Dieses Pseudonym wird bereits verwendet. Bitte wähle ein anderes.',
    'Pseudonyms can contain only lower case letters and numbers.': 'Pseudonyme können nur Kleinbuchstaben und Zahlen enthalten.',
    'You can change your pseudonym at most once a week': 'Du kannst dein Pseudonym höchstens einmal pro Woche ändern',
    "Let's get your profile set up": 'Lass uns dein Profil einrichten',
    'How do you want to appear to others in your posts and replies?': 'Wie möchten Sie in Ihren Beiträgen und Antworten auf andere wirken?',
    'Save': 'Speichern',
    'Edit {tLabel}': '{tLabel} bearbeiten',
    'photo and name': 'Foto und Name',
    'Please Wait...': 'Bitte warten...',
    'Continue': 'Fortsetzen',
    'Finish' : 'Fertig',


    'Join the Conversation': 'Unterhaltung zum Thema',
    "Join the conversation": 'Unterhaltung zum Thema',
    "Join the conversation to enter": "Treten Sie der Diskussion bei, um teilzunehmen",
    "Join the conversation to answer": "Treten Sie der Diskussion bei, um zu antworten",
    "Join the discussion": "Beteilige Dich am Gespräch",
    'question': 'Frage',
    'There {isAre} {count} {noun} being discussed': 'Es werden {count} {noun} diskutiert',
    '🚀 Read More': '🚀 Mehr lesen',
    'Related questions': 'Verwandte Fragen',
    'Ask a question related to this topic, or join the conversation by submitting an answer. Be sure to follow our [community guidelines](https://www.google.com).': 'Stelle eine Frage zu diesem Thema oder beteilige dich an der Konversation, indem du eine Antwort einreichst. Stelle sicher, dass du unsere [Gemeinschaftsrichtlinien](https://www.google.com) befolgst.',
    'Ask a question': 'Eine Frage stellen',
    'answer': 'Antwort',
    'answers': 'Antworten',
    'Your answer is posted': 'Deine Antwort wurde veröffentlicht',
    'story': 'Geschichte',
    'Community Guidelines': 'Community-Regeln',
    'Loading...': 'Lädt...',
    'Filter by opinion': 'Nach Meinung filtern',
    'Posting...': 'Wird veröffentlicht...',
    'Loading questions...': 'Fragen werden geladen...',
    'questions': 'Fragen',
    'Upvoted ({count})': 'Hochgewählt ({count})',
    'Log in to answer': 'Einloggen, um zu antworten',
    'No more comments': 'Keine weiteren Kommentare',
    'No more responses': 'Keine weiteren Beiträge',
    "Let's talk about...": 'Lasst uns reden',
    "Let's talk about": 'Lasst uns reden',

    // Moderation
    '🚧 **Moderation under construction:** We are still tuning auto-moderator triggering.': '🚧 **Moderation im Aufbau:** Wir justieren noch die Auslösung des Auto-Moderators.',
    'Consider rewording your post': 'Überlege, deinen Beitrag umzuformulieren',
    'Your post violates our [Community Guidelines](https://google.com).': 'Dein Beitrag verstößt gegen unsere [Gemeinschaftsrichtlinien](https://google.com).',
    'You are free to express any opinion, however you must do so in a respectful way that avoids personal attacks, assumes good intent, and backs up contentious claims with sources.': 'Du darfst jede Meinung äußern, musst dies jedoch auf eine respektvolle Weise tun, die persönliche Angriffe vermeidet, gute Absichten unterstellt und strittige Behauptungen mit Quellen untermauert.',
    'Sometimes our AI moderator triggers incorrectly. If that happened then first try adjusting your wording to be more obviously respectful. If you are still unable to get the moderator to accept a respectfully expressed opinion then please let us know.': 'Manchmal wird unser KI-Moderator fälschlicherweise ausgelöst. Wenn das passiert, versuche zuerst, deine Formulierungen offensichtlich respektvoller zu gestalten. Wenn es dir trotzdem nicht gelingt, dass der Moderator eine respektvoll geäußerte Meinung akzeptiert, lassen es uns bitte wissen.',
    'To provide transparency, we offer an anonymized [public database](https://google.com) of all moderation decisions.': 'Um Transparenz zu gewährleisten, bieten wir eine anonymisierte [öffentliche Datenbank](https://google.com) aller Moderationsentscheidungen an.',
    'Discuss with Human Moderator': 'Mit einem menschlichen Moderator diskutieren',
    'Updating...': 'Aktualisierung...',
    'Your response has been sent to a moderator for review': 'Deine Antwort wurde an einen Moderator zur Überprüfung gesendet',
    'You will be notified when the moderator makes a decision. In the meantime, you can look at the [transparency dashboard]({dashboardUrl}) to see where your post is in our moderation queue.':
    'Du wirst benachrichtigt, wenn der Moderator eine Entscheidung trifft. In der Zwischenzeit kannst du auf dem [Transparenz-Dashboard]({dashboardUrl}) sehen, wo dein Beitrag in unserer Moderationswarteschlange steht.',
    'I understand': 'Ich verstehe',
    'We strive for a kind and constructive discussion space where everyone feels welcome to contribute their perspectives and lived experiences to the conversation. This discussion space values responses that are on-topic and relevant to the subject matter, including meaningful debate.':'Wir möchten einen freundlichen und konstruktiven Austausch ermöglichen, bei dem alle sich eingeladen fühlen, ihre Perspektiven und gelebten Erfahrungen in das Gespräch einzubringen. In diesen Gesprächsräumen schätzen wir Beiträge und Perspektiven, die auf das Thema bezogen und für das Thema relevant sind, einschließlich sinnvoller Diskussionen und Debatten.',
    'To foster a welcoming and respectful environment, this discussion space uses AI-aided moderation to enforce the following community guidelines:':'Um eine einladende, respektvolle Umgebung zu schaffen, in der alle sich willkommen und sicher fühlen, nutzen wir zur Moderation die Hilfe von automatisierten und KI-unterstützten Systemen. Sie helfen uns folgende Community-Regeln zur Sicherheit aller durchzusetzen:',
    'No uncivil, hateful, or illegal content': 'Keine unanständigen, hasserfüllten, oder illegalen Inhalte',
    'No comments that call for or celebrate harm': 'Keine Inhalte, die zu Gewalt aufrufen oder Gewalt verherrlichen',
    'No insults, mockery, threats, or personal attacks': 'Keine Beleidigungen, Spott, Verhöhnungen, Drohungen oder persönlichen Angriffe',
    'Refrain from hostile comments that attach negative labels to, or assume bad intent of specific groups of people': 'Keine feindseligen Beiträge, die anderen Personengruppen negative Label oder verallgemeinernde negative Eigenschaften zuschreiben oder bösartige Absichten unterstellen',
    'Back up contentious claims with sources': 'Belegen Sie umstrittene Behauptungen mit Quellen',
    'No spam or commercial content': 'Kein Spam oder kommerzielle Inhalte',
    "Your post may have violated the following Community Guidelines:": "Dein Beitrag verstoßt gegen die folgenden Community-Regeln:",
    "Need help?": "Helfen Sie?",
    "Thank you!": "Danke!",
    'Your response has been sent to a moderator for review.': 'Deine Antwort wurde an einen Moderator zurprüfung gesendet',
    "📫 What happens next": "📫 Was passiert als nächstes",
    '✨ We use AI for moderation': '✨ Wir nutzen AI für moderatoren',
    'AI helps us to manage moderation, but sometimes it triggers incorrectly. If you are unable to get our AI to accept a respectfully expressed opinion, please send it to our moderators for review.': 'AI helfen uns, die Moderation zu verwalten, aber manchmal erzeugt es fehlerhafte. Wenn Sie die Moderationsentscheidung nicht akzeptieren, senden Sie sie an unsere Moderatoren für Überprüfung.',
    'Message to moderator...': 'Nachricht an Moderator ...',
    'Please explain why we should publish your post.': 'Erklären Sie, warum wir deinen Beitrag zurückgeben sollte.',
    "Done" : "Erledigt",
    'Respect the privacy of others and do not include personal information in your contributions': 'Respektieren Sie die Privatsphäre anderer und geben Sie keine persönlichen Daten preis.',
    'Your response is being reviewed by AI to ensure it follows our Community Guidelines': 'Deine Antwort wird von einer KI überprüft, um sicherzustellen, dass sie unseren Community-Regeln entspricht.',
    "Reviewing your perspective with AI to ensure it follows our Community Guidelines": "Uberprüfung Ihrer Perspektive mit der KI, um sicherzustellen, dass sie unseren Community-Regeln entspricht",
    'Perspectives must be relevant to the subject matter': 'Perspektiven müssen relevant für das Thema sein.',
    'Perspectives must foster productive discourse': 'Perspektiven muss produktiv diskutieren.',
    'You may have violated the following Community Guidelines:': 'Sie haben die folgenden Community-Regeln verstoßen:',
    "⚠️ Check your perspective": "⚠️ Überprüfen Sie Ihre Perspektive",
    "⚠️ Check your response": "⚠️ Überprüfen Sie Ihre Antwort",
    "AI review found that your perspective may be incompatible with the following ": "Die KI-Überprüfung hat ergeben, dass Ihre Perspektive möglicherweise nicht mit dem Folgenden übereinstimmt ",
    "AI review found that your response may be incompatible with the following ": "Die KI-Überprüfung hat ergeben, dass Ihre Antwort möglicherweise nicht mit dem Folgenden übereinstimmt ",
    "Consider rewording your perspective:": "Überlegen Sie, Ihre Perspektive umzuformulieren:",
    "Consider rewording your response:": "Überlegen Sie, Ihre Antwort umzuformulieren:",
    "Did AI get it wrong?": "Hat die KI einen Fehler gemacht?",
    "Send to a moderator for review": "An einen Moderator zur Überprüfung senden",
    "Express your ideas with civility": "Drücken Sie Ihre Ideen höflich aus",
    "No violent content or calls for harm": "Keine gewalttätigen Inhalte oder Aufrufe zu Schaden",
    "Avoid sarcasm and dismissive responses": "Vermeiden Sie Sarkasmus und abweisende Antworten",
    "Avoid derogatory labels": "Vermeiden Sie abwertende Bezeichnungen",
    "Provide sources for factual claims": "Geben Sie Quellen für Tatsachenbehauptungen an",
    "No spam or commercial promotion": "Kein Spam oder kommerzielle Werbung",
    "Respect the privacy of others, no personal information sharing": "Respektieren Sie die Privatsphäre anderer, teilen Sie keine persönlichen Informationen",
    "No off-topic posts": "Keine themenfremden Beiträge",
    "No illegal content": "Keine illegalen Inhalte",
    "Focus on ideas, rather than character judgments": "Konzentrieren Sie sich auf Ideen, nicht auf Charakterurteile",
    "Explain disagreements constructively": "Erklären Sie Meinungsverschiedenheiten konstruktiv",
    "Engage Respectfully": "Respektvoll handeln",
    "Support Your Claims": "Untermauern Sie Ihre Behauptungen",
    "No Harmful Content": "Keine schädlichen Inhalte",
    "No Disruptive Behavior": "Kein störendes Verhalten",
    "To foster a welcoming and respectful environment, this discussion space uses AI-aided moderation to support these community guidelines:": "Um ein herzlich und respektvolles Umfeld zu foster, verwendet diese Diskussionsbereich KI-aided Moderation, um diese Community-Regeln zu unterstehen:",

    // Comment Slider
    'Strongly yes': 'Stark ja',
    'Yes with reservations': 'Ja, aber mit Vorbehalten',
    'It\'s complicated': 'Es ist kompliziert',
    'No with reservations': 'Nein, aber mit Vorbehalten',
    'Strongly no': 'Stark nein',

    'Strongly disagree': 'Stark widersprechen',
    'Somewhat disagree': 'Etwas widersprechen',
    'Somewhat agree': 'Etwas zustimmen',
    'Strongly agree': 'Stark zustimmen',

    'Clear Filter': 'Filter löschen',
    'Your current public response': 'Deine aktuelle öffentliche Antwort',
    'Showing {responseCount} public responses and {voteCount} anonymous votes': 'Anzeige von {responseCount} öffentlichen Antworten und {voteCount} anonymen Stimmen.',
    'Tap to respond': 'Tippe, um zu antworten',
    'Slide to respond:': 'Zum Antworten schieben:',
    'Explain your response (optional)': 'Erkläre deine Antwort (optional)',
    'Public response': 'Öffentlicher Beitrag',
    'Anonymous vote': 'Anonyme Stimme',
    'Agree/Disagree': 'Zustimmen/Widersprechen',
    'Explain your response': 'Erkläre deine Antwort',
    'Share your thoughts... (optional)': 'Teile deine Gedanken... (optional)',

    // Question Screen
    'responses': 'Beiträge',
    'response': 'Beitrag',
    'Update your response': 'Bearbeite deinen Beitrag',
    'Only visible to you': 'Nur für dich sichtbar',
    '🔍 Under Review': '🔍 In Überprüfung',
    'Share': 'Teilen',
    'Your current response': 'Dein aktueller Beitrag',
    'There are no responses yet. Start the conversation!': 'Es gibt noch keine Antworten. Beginne die Konversation!',

    // Single Question
    'Choose a Question (Admin)': 'Wähle eine Frage (Admin)',
    'Choose a question': 'Wähle eine Frage',
    'Ask a question...': 'Stelle eine Frage...',
    'Choose': 'Wählen',
    'Chosen': 'Gewählt',
    'Choose a different question (admin)': 'Wähle eine andere Frage (Admin)',
    'Write your answer...': 'Schreibe deine Antwort...',
    'no answers yet': 'noch keine Antworten',

    // Multi question
    'Choose Questions for this Article': 'Wählen deine Fragen für diesen Artikel',
    'Edit questions (admin)': 'Fragen bearbeiten (Admin)',
    'Related Conversations': 'Verwandte Konversationen',
    'Add Question': 'Frage hinzufügen',
    'Editorial Question': 'Redaktionelle Frage',
    'Adding...' : 'Hinzufügen...',
    'Related Conversation': 'Verwandte Konversation',
    'Write the first response': 'Schreibe die erste Antwort',
    'Join': 'Beitreten',

    // Feature Module Names
    'Comment Slider': 'Kommentar-Slider',
    'Article Questions': 'Artikelfragen',
    'Pre-Moderation': 'Vor-Moderation',
    'Question Byline': 'Fragen-Verfasserzeile',

    // Moderation
    '**Avoid uncivil or disrespectful language**. You can express any opinion, however you must do so in a respectful way.': '**Vermeide unhöfliche oder respektlose Sprache**. Du kannst jede Meinung äußern, musst dies jedoch auf respektvolle Weise tun.',
    '**Avoid calls for harm or celebration of harm**. You can advocate for actions that would have negative consequences for some people, but harm should not be the primary goal.': '**Vermeide Aufrufe zu Schaden oder das Feiern von Schaden**. Du kannst dich für Maßnahmen einsetzen, die negative Folgen für manche Menschen haben würden, aber Schaden sollte nicht das Hauptziel sein.',
    '**Do not imply that someone has bad intent**. Assume they have good intentions and critique their ideas and their actions instead.': '**Gehe nicht davon aus, dass andere schlechte Absichten haben**. Gehe davon aus, dass sie gute Absichten haben und kritisiere stattdessen ihre Ideen und ihr Handeln.',
    '**Avoid attacking the character of others**. Critique their ideas and their actions instead.': '**Vermeide es, den Charakter anderer anzugreifen**. Kritisiere stattdessen ihre Ideen und ihr Handeln.',
    '**Avoid snark, sarcasm, and mockery**': '**Vermeide Sarkasmus und Spott**',
    '**Controversial claims must provide a source URL**': '**Kontroverse Behauptungen müssen eine Quell-URL angeben**',
    '**Be sure to write your response in a way that those who disagree with will be willing to listen to**': '**Stelle sicher, dass deine Antwort so formuliert ist, dass diejenigen, die nicht zustimmen, bereit sind zuzuhören**',

    '⚠️ Take another look': '⚠️ Nochmal ansehen',
    'Your post may violate our [Community Guidelines](https://example.com).': 'Dein Beitrag verstößt möglicherweise gegen unsere [Gemeinschaftsrichtlinien](https://example.com).',
    '**Sometimes our AI triggers incorrectly**': '**Manchmal wird unser KI-Moderator fälschlicherweise ausgelöst**',
    'If that happened then first try adjusting your wording to be more obviously respectful. If you are still unable to get the AI to accept a respectfully expressed opinion then please send it to our human moderator.': 'Wenn das passiert, versuche zuerst, deine Formulierungen offensichtlich respektvoller zu gestalten. Wenn es dir trotzdem nicht gelingt, dass der Moderator eine respektvoll geäußerte Meinung akzeptiert, sende sie bitte an unseren menschlichen Moderator.',
    'To provide transparency, we offer an anonymized [public database]({dashboardUrl}) of all moderation decisions.': 'Um Transparenz zu gewährleisten, bieten wir eine anonymisierte [öffentliche Datenbank]({dashboardUrl}) aller Moderationsentscheidungen an.',
    'Send to moderator': 'An Moderator senden',
    'Message to moderator*': 'Nachricht an Moderator*',
    'Please explain your response (required)': 'Bitte erläutere deine Antwort (erforderlich)',
    'Delete': 'Löschen', 
    'Rejected': 'Abgelehnt', 
    'Approved': 'Genehmigt', 
    'Awaiting Decision': 'Entscheidung ausstehend', 
    'Moderation Queue': 'Moderations-Warteschlange', 
    'Back': 'Zurück',
    'Close': 'Schließen',
    'Moderator Explanation': 'Erklärung des Moderators',
    'Explain your decision...': 'Erkläre deine Entscheidung...',
    'Approve': 'Genehmigen',
    'Reject': 'Ablehnen',
    '**Message to moderator**: {appealText}': 'Nachricht an den Moderator: {appealText}',
    'Moderation Transparency Dashboard': 'Transparentes Moderations-Dashboard',

    //Blacklist-Feature
    'Blacklisted Terms': 'Blacklist-Begriffe',
    'Blacklist is empty.': 'Blacklist ist leer.',
    'Blacklist': 'Blacklist' ,
    'Select': 'Auswählen',
    'Deselect': 'Abwählen',
    'Please enter at least one valid term.': 'Bitte gib mindestens einen gültigen Begriff ein.',
    'The following term(s) already exist in the blacklist.': 'Die folgenden Begriffe existieren bereits in der Blacklist.',
    'Adding term(s)...': 'Begriffe werden hinzugefügt...',
    'Added term(s) successfully!': 'Begriffe erfolgreich hinzugefügt!',
    'Deleting term(s)...': 'Begriffe werden gelöscht...',
    'Deleted term(s) successfully!': 'Begriffe erfolgreich gelöscht!',
    'Term(s) are already in blacklist': 'Begriff(e) sind bereits auf der Blacklist',
    'Enter new terms, separated by new lines': 'Neue Begriffe eingeben, getrennt durch Zeilenumbrüche',
    'Add to blacklist': 'Zur Blacklist hinzufügen',
    'Hold Shift and double-click to select or deselect multiple terms.': 'Umschalttaste gedrückt halten und doppelklicken, um mehrere Begriffe aus- oder abzuwählen.',
    'Delete selected': 'Ausgewählte Begriffe löschen',
    'Delete terms?': 'Begriffe wirklich löschen?',
    'Do you really want to delete the selected term(s)? This action cannot be undone.': 'Möchtest du die ausgewählten Begriffe wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.',

    // Reactions
    'Thank you': 'Danke',
    'New to me': 'Neu für mich',
    'Respect': 'Respekt',
    'Disagree': 'Nicht einverstanden',
    'Best': 'Am besten',
    'Provocative': 'Provokativ',
    'Clarifying': 'Klärend',
    'Relatable': 'Nachvollziehbar',
    'Helpful': 'Hilfreich',
    'Controversial': 'Kontrovers',
    'Interesting': 'Interessant',

    // Representing Perspectives
    'Perspective Editor (Admin)': 'Perspektiven-Editor (Admin)',
    'Perspective List': 'Perspektivenliste',
    'What perspectives are important to this conversation? (newline separated)': 'Welche Perspektiven sind wichtig für diese Konversation? (durch Zeilenumbruch getrennt)',
    "Add your perspective": "Füge deine Perspektive hinzu",
    "Perspectives": "Perspektiven",
    "We haven't heard from these perspectives yet": "Wir haben noch nichts von diesen Perspektiven gehört",
    'Add a response': 'Eine Antwort hinzufügen',
    'How do you relate to this issue?': 'Wie stehst du zu diesem Thema?',
    'Sort by perspective': 'Nach Perspektive sortieren',
    'Apply': 'Anwenden',
    'Show more responses': 'Mehr Antworten anzeigen',
    'Edit perspectives (Admin)': 'Perspektiven bearbeiten (Admin)',
    'What perspectives do you want to see first?': 'Welche Perspektiven möchtest du zuerst sehen?',
    "We'd particularly like to hear from:": "Welche Sicht hast Du auf das Thema?",
    "Add your own": "Füge deine eigene hinzu",
    "Write in your own": "Füge deine eigene hinzu",
    "Write in a perspective": "Eigene Perspektive vorschlagen",


    // Other
    'No main screen defined.': 'Kein Hauptbildschirm definiert.',


    // Video Voting - Intro screen
    'Enable voting': 'Voting aktiv',
    'Vote anonymously': 'Anonymisiert abstimmen',
    'Start Video': 'Video starten',
    'This video contains audience questions that you can participate in. You can vote anonymously, skip individual questions, or disable this feature. In the ' :
    'Dieses Video enthält Zuschauerfragen, an denen du dich beteiligen kannst. Du kannst diese Funktion deaktivieren, anonym abstimmen und einzelne Fragen überspringen. Über die ',
    'settings': 'Einstellungen',
    ' you can deactivate it for all videos.': ' kannst du die Funktion generell deaktivieren.',
    "You have to be logged in to use this feature.\nThe video will play as usual and you won't see any votings.\n**Click here to log in**." :
    "Du musst angemeldet sein, um diese Funktion nutzen zu können.\nDas Video wird wie gewohnt abgespielt und du siehst keine Abstimmungen.\n**Klicke hier, um dich einzuloggen**.",

    // Video Voting - Voting flow
    'Discuss with others': 'Tausche dich mit anderen aus',
    'Continue': 'Fortsetzen',
    'Take a look at the voting in the overview': 'Sieh dir die Abstimmung in der Übersicht an',
    "What do you think about it now?": "Wie denkst du jetzt darüber?",

    // Video Voting - Sidebar
    'Video Voting': 'Video Voting',
    'Voting Results': 'Abstimmungsergebnisse',
    'All responses': 'Alle Kommentare',
    'Votings': 'Abstimmungen',
    "Time:": "Zeit:",
    'END': 'ENDE',
    "No voting data available.": "Keine Abstimmungsdaten vorhanden",
    'Go to video': 'Zum Video',

    // Video Voting - User profile
    "Settings": "Einstellungen",
    "Enable voting for all videos": "Voting für alle Videos aktivieren",

    // Video Voting - Administrative text
    'Manage Video Voting (Admin)': 'Video Voting verwalten (Admin)',
    "Please create default question threads first.": "Bitte erstelle zuerst die Standardfragen-Threads.",
    "Create Default Questions": "Standardfragen erstellen",
    "Create a Video Voting for this Article": "Erstelle eine Videoabstimmung für diesen Artikel",
    "This article does not have a video voting yet.": "Dieser Artikel hat noch keine Videoabstimmung.",
    "Individual Questions": "Individualfragen",
    "Connected Questions": "Verlaufsfragen",
    "Endometriosis (German)": "Endometriose (Deutsch)",
    "Endometriosis (English)": "Endometriose (Englisch)",
    "Open relationships (German)": "Offene Beziehungen (Deutsch)",
    "Open relationships (English)": "Offene Beziehungen (Englisch)",
    "Create Video Voting": "Videoabstimmung erstellen",
    "Delete Video Voting": "Videoabstimmung löschen",
    "The video voting template is missing": "Die Vorlage für die Videoabstimmung fehlt",
    "Video voting is not enabled for this video.": "Video Voting ist für dieses Video nicht aktiviert.",
    'Video voting is enabled for this video.' : 'Video Voting ist für dieses Video aktiviert.',
    'Video voting is enabled for this video. To participate, please start the video.': 'Video Voting ist für dieses Video aktiviert. Um teilzunehmen musst du eingeloggt sein.',

    // Conversation History
    'Conversation history': 'Konversationsverlauf',
    'Visible to others': 'Sichtbar für andere',
    'Your conversation history is always visible to admins and moderators.': 'Dein Konversationsverlauf ist immer für Admins und Moderatoren sichtbar.',
    'Visible only to admins': 'Nur für Admins sichtbar',
    'This user has not made their conversation history public.': 'Dieser Benutzer hat seinen Konversationsverlauf nicht öffentlich gemacht.',

    // ToM
    'Live': 'Live',
    'Ended': 'Beendet',
    'Follow': 'Folgen',
    'Following': 'Folge ich',
    'Topic of the moment': 'Thema des Moments',
    'participant': 'teilnehmer',
    'participants': 'teilnehmer',
    'Ask us a question...': 'Stelle uns eine Frage...',
    'Your question will be considered for future content pieces. Please note, we may not be able to answer all questions.':
        'Deine Frage wird für zukünftige Inhalte berücksichtigt. Bitte beachte, dass wir möglicherweise nicht alle Fragen beantworten können.',
    'Popular questions': 'Beliebte Fragen',
    'View all questions': 'Alle Fragen anzeigen',
    'Be the first to share your thoughts.': 'Sei der Erste, der seine Gedanken teilt.',
    'See all': 'Alle anzeigen',
    'Discuss this topic with other citizens': 'Diskutiere dieses Thema mit anderen Bürgern',
    'Response': 'Beitrag',
    'Responses': 'Beiträge',
    'Need more information to make up your mind?': 'Brauchst du mehr Informationen, um dir eine Meinung zu bilden?',
    'Previous': 'Vorherige',
    'Next': 'Nächste',
    'Previous topics covered': 'Vorherige behandelte Themen',
    'No more questions': 'Keine weiteren Fragen',
    'All questions': 'Alle Fragen',
    'Filters': 'Filter',
    'Filter': 'Filter',
    'Sort by': 'Sortieren nach',
    'Most recent': 'Neueste',
    'Oldest': 'Älteste',
    'Most popular': 'Beliebteste',
    'Pin': 'Anpinnen',
    'Upcoming': 'Bevorstehend',

    // Conversation Helper
    "Help history": "Hilfeverlauf",
    "Conversation Help": "Gesprächshilfe",
    "Hello everyone!": "Hallo zusammen!",
    "Open dashboard (admin)": "Admin-Dashboard öffnen",
    "Conversation Helper": "Gesprächshelfer",
    "Add-ons": "Erweiterungen",
    "Reactions": "Reaktionen",
    "Help History": "Hilfeverlauf",
    "Conversation Summary": "Gesprächszusammenfassung",
    "Private Comment Feedback": "Privates Kommentar-Feedback",
    "Timing of Analysis": "Analysezeitpunkt",
    "Automatic analysis": "Automatische Analyse",
    "Analyze after every...": "Analysiere nach jedem...",
    "1 comment": "Kommentar",
    "3 comments": "3. Kommentar",
    "5 comments": "5. Kommentar",
    "10 comments": "10. Kommentar",
    "Only manual analysis": "Nur manuelle Analyse",
    "Update guidance if there are no problems": "Hinweis aktualisieren, wenn es keine Probleme gibt",
    "Update based on comments...": "Kommentarbasiert aktualisieren...",
    "Never": "Nie",
    "Every comment": "Nach jedem Kommentar",
    "Every 3 comments": "Alle 3 Kommentare",
    "Every 5 comments": "Alle 5 Kommentare",
    "Every 10 comments": "Alle 10 Kommentare",
    "Update based on time...": "Zeitbasiert aktualisieren...",
    "Immediately": "Sofort",
    "Every 10 min": "Alle 10 Minuten",
    "Every 30 min": "Alle 30 Minuten",
    "Every hour": "Jede Stunde",
    "Scope of Analysis": "Analyseumfang",
    "Analyze...": "Analysiere...",
    "All comments": "Alle Kommentare",
    "Most recent x% of comments": "Neuste x% der Kommentare",
    "New comments since last analysis": "Neue Kommentare seit letzter Analyse",
    "Use percentage...": "Wähle Prozentsatz...",
    "Most recent 10%": "Neuste 10%",
    "Most recent 25%": "Neuste 25%",
    "Most recent 50%": "Neuste 50%",
    "Apply percentage...": "Verwende Prozentsatz...",
    "Always": "Immer",
    "After 5 comments": "Nach 5 Kommentaren",
    "After 10 comments": "Nach 10 Kommentaren",
    "Auto-Moderator Personality": "Auto-Moderator Persönlichkeit",
    "Use personality...": "Verwende Persönlichkeit...",
    "Personality A": "Persönlichkeit A",
    "Personality B": "Persönlichkeit B",
    "Personality C": "Persönlichkeit C",
    "Conversation Helper Dashboard": "Gesprächshelfer Dashboard",
    "Related Thread": "Zugehörige Unterhaltung",
    "Current Guidance": "Aktueller Hinweis",
    "This guidance message is visible to everyone.": "Dieser Hinweis ist für alle sichtbar.",
    "This message was generated because...": "Dieser Hinweis wurde generiert, weil...",
    "Generate new guidance": "Neuen Hinweis generieren",
    "Conversation Analysis": "Gesprächsanalyse",
    "Analyze conversation": "Gespräch analysieren",
    "Result: ": "Ergebnis: ",
    "Problems detected!": "Probleme gefunden!",
    "No problems detected.": "Keine Probleme gefunden.",
    "Explanation": "Erklärung",
    "Conversation Stats": "Gesprächsstatistiken",
    "- Last conversation analysis: **{time}**": "- Letzte Gesprächsanalyse: **{time}**",
    "- Last conversation analysis: **None**": "- Letzte Gesprächsanalyse: **Keine**",
    "- **{count} check(s)** for problems performed": "- **{count} Gesprächsanalyse(n)** durchgeführt",
    "- **{count} guidance message(s)** generated": "- **{count} Hinweis(e)** generiert",
    "- **{count} new comment(s)** since last conversation analysis": "- **{count} neue(r) Kommentar(e)** seit der letzten Analyse",

    // np-platform overwrites
    'Share your thoughts' : "Dein Beitrag zum Thema",
    'Share your thoughts...': 'Dein Beitrag zum Thema...',
    'Be the first to share your thoughts' : "Sei die erste Person, die ihre Gedanken teilt",
    "Update": "Posten",
    "Post": "Posten",

    // Context Entry Points
     "You are joining from:": "Du bist von:",
     "Joining from:": "Du bist von:",
     "Read article": "Artikel lesen",
     "Joined from": "Beigetreten von",

    // Reading List / Topic
    "Reading List": "Weitere Inhalte",
    "Learn more about this topic": "Erfahre mehr zu diesem Thema",
    "Discussion topic": "Diskussions-Thema",
    "Show more": "Mehr anzeigen",
    'Manage related articles': 'Verwandte Artikel verwalten',
    'Edit related conversations': 'Verwandte Konversationen bearbeiten',

    // Moderation Dashboard
    'Message to moderator:': 'Nachricht an Moderator:',
    "Today, ": "Heute, ",
    "Yesterday, ": "Gestern, ",
    
    // Missing tranlsations
    'Sending...': 'Senden...',
    '🚫 Rejected by Human Moderator': '🚫 Von menschlichem Moderator abgelehnt',
    'Select one option': 'Wählen Sie eine Option',
    'Submit my vote': 'Meine Stimme abgeben',
    '🚧 Temporarily Closed': '🚧 Vorübergehend geschlossen',
    'This conversation has been temporarily closed. Please check back soon for it to re-open.': 'Dieses Gespräch wurde vorübergehend geschlossen. Bitte schauen Sie bald wieder vorbei, um es erneut zu öffnen.',
    'Delete this post?': 'Diesen Beitrag löschen?',
    'This action can\'t be undone': 'Diese Aktion kann nicht rückgängig gemacht werden',
    'You': 'Sie',
    'You do not have access to this feature': 'Sie haben keinen Zugriff auf diese Funktion',
    'All posts will be manually moderated for civility': 'Alle Beiträge werden manuell auf Höflichkeit geprüft',
    'What happens next': 'Was passiert als Nächstes',
    'Your response will be reviewed by a moderator': 'Ihre Antwort wird von einem Moderator überprüft',
    'You should get a response within 24 hours.': 'Sie sollten innerhalb von 24 Stunden eine Antwort erhalten.',
    'If your response is approved, it will be posted.': 'Wenn Ihre Antwort genehmigt wird, wird sie veröffentlicht.',
    'If your response is not approved, you will be notified and given the opportunity to edit and resubmit.': 'Wenn Ihre Antwort nicht genehmigt wird, werden Sie benachrichtigt und haben die Möglichkeit, sie zu bearbeiten und erneut einzureichen.',
    'Continue with Github': 'Mit Github fortfahren',
    'Continue with Radio Canada': 'Mit Radio Canada fortfahren',
    'Continue with Radio Canada Internal': 'Mit Radio Canada Intern fortfahren',
    'Logging in...': 'Anmeldung...',
    'Beta': 'Beta',
    'Saving...': 'Speichern...',
    'Your response is under review': 'Ihre Antwort wird überprüft',
    'Your response was rejected by a moderator': 'Ihre Antwort wurde von einem Moderator abgelehnt',
    'Conversation History': 'Gesprächsverlauf',
    'Profile Deletion': 'Profil löschen',
    'Delete data': 'Daten löschen',
    'Are you sure you want to delete your profile?': 'Sind Sie sicher, dass Sie Ihr Profil löschen möchten?',
    'Deleting your profile cannot be undone. Any data and activity associated with your profile may take up to 72 hours to be removed.': 'Das Löschen Ihres Profils kann nicht rückgängig gemacht werden. Alle mit Ihrem Profil verbundenen Daten und Aktivitäten können bis zu 72 Stunden für die Entfernung benötigen.',
    'Deleting profile data. Please wait...': 'Profil-Daten werden gelöscht. Bitte warten...',
    'Other': 'Andere',
    'Report': 'Melden',
    'Create a topic': 'Thema erstellen',
    'PLAY': 'SPIE',
    'PREV': 'ZURÜ',
    'FWRD': 'WEIT',
    'PAUSE': 'PAUS',
    'START': 'STAR',
    'No question for this content': 'Keine Frage zu diesem Inhalt',
    'Open admin screen': 'Admin-Bildschirm öffnen',
    'No admin screen defined. Use the feature selector to enable a feature.': 'Kein Admin-Bildschirm definiert. Verwenden Sie den Funktionsauswähler, um eine Funktion zu aktivieren.',
    'Change': 'Ändern',
    'Configure Teaser Features (admin)': 'Teaser-Funktionen konfigurieren (Admin)',
    'Pinned': 'Angeheftet',
    'Which community guideline does this violate?': 'Gegen welche Community-Richtlinie verstößt dies?',
    'Add': 'Hinzufügen',
    'Select topics for article (admin)': 'Themen für den Artikel auswählen (Admin)',
    'Choose Topics for this Article': 'Wählen Sie Themen für diesen Artikel',
    'Sarahs Leben mit dem Schmerz': 'Sarahs Leben mit dem Schmerz',
    'Load video element': 'Video-Element laden',
    'Individual questions': 'Einzelfragen',
    'Connected questions': 'Verbundene Fragen',
    'Admin Dashboard': 'Admin-Dashboard',
    'Quick Links': 'Schnellzugriffe',
    'Component Demo': 'Komponentendemo',
    'Event Log': 'Ereignisprotokoll',
    'Only an admin can see this page': 'Nur ein Administrator kann diese Seite sehen',
    'Admin Users': 'Admin-Benutzer',
    'Loading admin users...': 'Admin-Benutzer werden geladen...',
    'Add new admin users': 'Neue Admin-Benutzer hinzufügen',
    'Emails of users to add': 'E-Mail-Adressen der hinzuzufügenden Benutzer',
    'Owner': 'Eigentümer',
    'Developer': 'Entwickler',
    'Super-Moderator': 'Super-Moderator',
    'Moderator': 'Moderator',
    'Editorial': 'Redaktion',
    'Analyst': 'Analyst',
    'Updating roles...': 'Rollen werden aktualisiert...',
    'Add Admins': 'Admins hinzufügen',
    'Adding Admins...': 'Admins werden hinzugefügt...',
    'Request Data': 'Daten anfordern',
    'Send an email to [privacy@newpublic.org](mailto:privacy@newpublic.org) to request a copy of your data associated with this discussion space. Once submitted, please allow up to 30 days for us to process your request.': 'Senden Sie eine E-Mail an [privacy@newpublic.org](mailto:privacy@newpublic.org), um eine Kopie Ihrer Daten anzufordern, die mit diesem Diskussionsbereich verbunden sind. Nach Absenden der Anfrage erlauben Sie bitte bis zu 30 Tage, um Ihre Anfrage zu bearbeiten.',

    // Jan 13 String Review 
    "Send an email to [privacy@newpublic.org](mailto:privacy@newpublic.org) to request a copy of your data associated with this discussion space. It may take up to 30 days to process your request.": "Senden Sie eine E-Mail an [privacy@newpublic.org](mailto:privacy@newpublic.org), um eine Kopie Ihrer mit diesem Diskussionsbereich verbundenen Daten anzufordern. Die Bearbeitung Ihrer Anfrage kann bis zu 30 Tage dauern.",
    "AI helps us moderate comments, but sometimes it can make a mistake. If AI is preventing you from sharing a respectful opinion, send your comment to a moderator for review.": "KI hilft uns, Kommentare zu moderieren, kann aber manchmal Fehler machen. Wenn die KI Sie daran hindert, eine respektvolle Meinung zu teilen, senden Sie Ihren Kommentar zur Überprüfung an einen Moderator.",
    "This discussion is temporarily unavailable. Check back for updates.": "Diese Diskussion ist vorübergehend nicht verfügbar. Schauen Sie später für Updates vorbei.",
    "🚧 Temporarily Unavailable": "🚧 Vorübergehend nicht verfügbar",
    "We strive for a kind and constructive discussion space where everyone feels welcome to share their perspectives and experiences. You can contribute to a discussion with responses that are on-topic and relevant to the subject matter, including meaningful debate.": "Wir bemühen uns um einen freundlichen und konstruktiven Diskussionsraum, in dem sich jeder willkommen fühlt, seine Perspektiven und Erfahrungen zu teilen. Sie können durch themenbezogene und relevante Antworten, einschließlich sinnvoller Debatten, zur Diskussion beitragen.",
    "To maintain a welcoming and respectful environment, this space uses AI to help moderate comments. AI may flag your post for your additional review if it does not follow the community guidelines:": "Um eine einladende und respektvolle Umgebung zu erhalten, nutzt dieser Bereich KI zur Moderation von Kommentaren. KI kann Ihren Beitrag zur weiteren Überprüfung markieren, wenn er nicht den Community-Richtlinien entspricht:",
    "AI review found that your perspective label may be incompatible with the following ": "Die KI-Überprüfung hat ergeben, dass Ihr Perspektiven-Label möglicherweise mit den folgenden Punkten unvereinbar ist: ",
    "Your response": "Ihre Antwort",
    "Choose a name and photo that appears when you post in discussions.": "Wählen Sie einen Namen und ein Foto aus, das bei Ihren Beiträgen in Diskussionen angezeigt wird.",
    "Set up your profile": "Profil einrichten",
    "Custom names can only include lowercase letters and numbers.": "Benutzerdefinierte Namen dürfen nur Kleinbuchstaben und Zahlen enthalten.",
    "Custom names can only be changed once per week.": "Benutzerdefinierte Namen können nur einmal pro Woche geändert werden.",
    "This custom name is already in use.": "Dieser benutzerdefinierte Name wird bereits verwendet.",
    "Reviewing your perspective label with AI to ensure it follows our Community Guidelines": "Überprüfung Ihres Perspektiven-Labels durch KI, um sicherzustellen, dass es den Community-Richtlinien entspricht.",
    "Are you sure you want to delete your profile data?": "Sind Sie sicher, dass Sie Ihre Profildaten löschen möchten?",
    "Delete my data": "Meine Daten löschen",
    "Request my data": "Meine Daten anfordern",
    "**Share details** (required)": "**Details angeben** (erforderlich)",
    "Message to Moderator...": "Nachricht an den Moderator...",
    "user123": "benutzer123",
    "Add a custom name": "Einen benutzerdefinierten Namen hinzufügen",
    "Visible to everyone": "Für alle sichtbar",
    "Visible to admins": "Für Administratoren sichtbar",
    "Discussion History": "Diskussionsverlauf",
    "This user's history is private.": "Der Verlauf dieses Benutzers ist privat.",
    "Admins and Moderators can view your history.": "Administratoren und Moderatoren können Ihren Verlauf einsehen.",
    "What guideline does this violate? Select all that apply.": "Gegen welche Richtlinie verstößt dies? Wählen Sie alle zutreffenden Optionen aus.",
    "Admin Only": "Nur für Administratoren",
    "Add new Admins": "Neue Administratoren hinzufügen",
    "Loading Admins...": "Administratoren werden geladen...",
    "You will receive an email notification when your post is approved or rejected by moderators.": "Sie erhalten eine E-Mail-Benachrichtigung, wenn Ihr Beitrag von den Moderatoren genehmigt oder abgelehnt wird.",
    "Posts are manually moderated to meet the ": "Beiträge werden manuell moderiert, um die einzuhalten ",
    "If requested, your account's discussion history and engagement in any discussion is deleted as part of the request. This action cannot be undone.": "Auf Anfrage werden der Diskussionsverlauf Ihres Kontos und Ihre Beteiligung an Diskussionen im Rahmen der Anfrage gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.",
    "This custom name may violate our community guidelines.": "Dieser benutzerdefinierte Name könnte gegen unsere Community-Richtlinien verstoßen.",
    "Custom names are not allowed to look like real names.": "Benutzerdefinierte Namen dürfen nicht wie echte Namen aussehen.",
    "perspective label": "Perspektiven-Label",
    "This custom name is incompatible with our Community Guidelines.": "Dieser benutzerdefinierte Name ist nicht mit unseren Community-Richtlinien kompatibel.",

    //Moderation Dashboard
    "🔥 Heated": "🔥 Erhitzt",
    "Reported by users ": "Von Benutzern gemeldet",
    "Linked stories ": "Verknüpfte Geschichten",
    "{numArticles} linked stories": "{numArticles} verknüpfte Geschichten",
    "remove": "entfernen",
    "Provide any details to help us determine if your post follows our Community Guidelines.": "Geben Sie Details an, die uns helfen festzustellen, ob Ihr Beitrag unseren Community-Richtlinien entspricht.",
    "Awaiting decision": "Entscheidung ausstehend",
    "Automatically approved": "Automatisch genehmigt",
    "Humanly approved": "Manuell genehmigt",
    "Humanly rejected": "Manuell abgelehnt",
    "Origin:": "Herkunft:",
    "Comment information": "Kommentarinformationen",
    "Posted": "Veröffentlicht",
    "Status": "Status",
    "Internal Notes": "Interne Notizen",
    "Add your notes...": "Fügen Sie Ihre Notizen hinzu...",
    "AI flag": "KI-Markierung",
    "📌 Community guideline violation": "📌 Verstoß gegen die Community-Richtlinien",
    "• No violent content or calls for harm": "• Keine Gewaltinhalte oder Aufrufe zu Schaden",
    "User information": "Benutzerinformationen",
    "Member since ": "Mitglied seit ",
    "Show less": "Weniger anzeigen",
    "comments & replies": "Kommentare & Antworten",
    "reported comments": "Gemeldete Kommentare",
    "flagged comments": "Von KI markierte Kommentare",
    "rejected comments": "Abgelehnte Kommentare",
    "user warnings": "Benutzerwarnungen",
    "No origin available": "Keine Herkunft verfügbar",
    "View": "Ansehen",
    "User report": "Benutzerbericht",
    "Human rejected ": "Manuell abgelehnt ",
    "• No spam or commercial content": "• Kein Spam oder kommerzielle Inhalte",
    "Rejected by": "Abgelehnt von",
    "Reported by": "Gemeldet von",
    "Reported by users": "Von Benutzern gemeldet",
    "You are reviewing": "Sie überprüfen gerade",
    "Back to selection": "Zurück zur Auswahl",
    "Which community guideline does this comment violate?": "Gegen welche Community-Richtlinie verstößt dieser Kommentar?",
    "Want to do anything else?": "Möchten Sie noch etwas anderes tun?",
    "Warn user": "Benutzer warnen",
    "No thanks": "Nein, danke",
    "Newest": "Neueste",
    "Warnings": "Warnungen",
    "PSI Moderation": "PSI-Moderation",
    "No comments.": "Keine Kommentare.",
    "View in thread": "Im Thread ansehen",
    "Linked stories": "Verknüpfte Geschichten",
    "Threads": "Threads",
    "Open Threads": "Offene Threads",
    "Closed Threads": "Geschlossene Threads",
    "Closed": "Geschlossen",
    "User settings": "Benutzereinstellungen",
    "Block user": "Benutzer blockieren",
    "Block this user?": "Diesen Benutzer blockieren?",
    "Do you really want to block this user? You can unblock the user via the profile page.": "Möchten Sie diesen Benutzer wirklich blockieren? Sie können ihn über die Profilseite entsperren.",
    "Blocked": "Blockiert",
    "Unblock user": "Benutzer entsperren",
    "Unblock this user?": "Diesen Benutzer entsperren?",
    "Do you really want to unblock this user?": "Möchten Sie diesen Benutzer wirklich entsperren?",
    "Open": "Offen",

    //AITag
    "To maintain a welcoming and respectful environment, this space uses AI to moderate comments. AI may flag your post for your additional review if it does not follow the community guidelines.": "Um eine einladende und respektvolle Umgebung zu gewährleisten, nutzt dieser Bereich KI, um Kommentare zu moderieren. Die KI kann Ihren Beitrag zur zusätzlichen Überprüfung markieren, wenn er nicht den Community-Richtlinien entspricht.",
    "To maintain a welcoming and respectful environment, this space uses AI to moderate comments. AI may flag your post for your additional review if it does not follow the community guidelines. Learn more.": "Um eine einladende und respektvolle Umgebung zu gewährleisten, nutzt dieser Bereich KI, um Kommentare zu moderieren. Die KI kann Ihren Beitrag zur zusätzlichen Überprüfung markieren, wenn er nicht den Community-Richtlinien entspricht. Erfahren Sie mehr.",
    "AI-moderated": "Von KI moderiert",

}


