import { useEffect } from 'react';
import { callServerApiAsync } from "system/servercall.js"
import { signInWithTokenAsync } from 'util/firebase.js'
import { getIsInAndroid, getIsInIFrame, getIsInIOS } from 'util/util.js'


const useHydrateSessionFromParent = () => {
    useEffect(() => {
        const hydrateSessionTokenFn = async (ssoToken, provider) => {
            const result = await callServerApiAsync({
                component: 'auth', funcname: 'convertToken', params: {
                    ssoToken, provider
                }
            });
            if (result?.loginToken) {
                await signInWithTokenAsync(result.loginToken);
            }
        };

        const hydrateSessionListener = async (event) => {
            const { ssoToken, provider } = event?.detail;
            await hydrateSessionTokenFn(ssoToken, provider);
        }

        document.addEventListener("nativeMessageEvent", hydrateSessionListener);

        if (getIsInAndroid(window)) {
            window.Android.appReady();
        } else if (getIsInIOS(window)) {
            window.webkit.messageHandlers.appReady.postMessage('');
        } else if (getIsInIFrame(window)) {
            window.parent.postMessage('psi-app-ready', '*');
        }

        return () => {
            document.removeEventListener('nativeMessageEvent', hydrateSessionListener);
        };
    }, []);
};

export default useHydrateSessionFromParent;