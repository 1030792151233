import { Pin, PinFilled } from "@carbon/icons-react";
import { useIsAdmin } from "component/admin.js"
import { SubtleButton } from "component/button.js"
import { useDatastore, useGlobalProperty } from "util/datastore.js"

export const PinCommentFeature = {
    name: 'Pin Comment in Teaser',
    key: 'pin_comment',
    config: {
        commentActions: [ActionPinComment]
    }
}

function ActionPinComment({commentKey}) {
    const pins = useGlobalProperty('pinned_comments');
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const pinned = pins?.[commentKey];

    if (!isAdmin) {
        return null;
    }

    async function onPin() {
        const newPins = {...pins, [commentKey]: pinned ? null : true}
        datastore.setGlobalProperty('pinned_comments', newPins);
        await datastore.callServerAsync('derivedviews', 'runTriggers', {
            type: 'comment', key: commentKey
        });
    }

    const label = pinned ? 'Pinned' : 'Pin';

    return <SubtleButton icon={pinned ? PinFilled : Pin} padRight
        ariaLabel={label} label={label} onPress={onPin} 
    />
}

