import React from "react";

import {CommentsInput} from "component/comment.js"
import {Paragraph} from "component/text.js"
import {useGlobalProperty} from "util/datastore.js"
import {colorBlack, grey6, hydrangea3} from "component/color.js"

import {AskMeAnythingLiveStatus, TopicDate} from "../../../component/cbcrc/header";
import {formatToDateRangeString} from "../../../component/cbcrc/date";

import { spacings } from "component/constants"
import {Pad, PadBox} from "component/basics.js"



export const AskMeAnythingHeaderFeature = {
    key: 'amaheader',
    name: 'Ask me anything header',
    config: {
        topicHeaderLeftWidgets: [AskMeAnythingLiveStatusWidget],
        topicDescriptionWidget: AskMeAnythingStatusDescription,
        topicHeaderBottomWidgets: [AskMeAnythingHeaderBottomWidget],
        headerBackgroundColor: hydrangea3,
        headerFontColor: colorBlack,
    }
}

function AskMeAnythingLiveStatusWidget() {
    const status = useGlobalProperty('status');
    const live = status === 'live';

    return <AskMeAnythingLiveStatus live={live} />
}

function AskMeAnythingStatusDescription() {
    return null;
}

function AskMeAnythingHeaderBottomWidget() {

    const status = useGlobalProperty('status');
    const live = status === 'live';
    return live ?
        <PadBox top={spacings.xl} bottom={spacings.sm}>
            <CommentsInput />
            <Pad size={spacings.sm} />
            <Paragraph color={grey6} label='Your question will be considered for future content pieces. Please note, we may not be able to answer all questions.' />
        </PadBox>
        :
        <PadBox vert={spacings.xl}  bottom={spacings.sm}>
            <TopicDate date={formatToDateRangeString({start: new Date(startAt), end: new Date(endAt), locale: 'fr-CA'})} />
        </PadBox>
}
