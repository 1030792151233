import { useState } from "react";
import React from "react";
import { ConversationScreen, HorizBox, Pad, PadBox, Card, Separator, Center } from "component/basics.js"
import { CTAButton } from "component/button.js"
import { ActionEdit, ActionReply, ActionReport, BasicComments, CommentsInput, ComposerScreen } from "component/comment.js"
import { Heading, Paragraph, UtilityText } from "component/text.js"
import { useCollection, useDatastore, useGlobalProperty, useInstanceKey, useObject } from "util/datastore.js"
import { Catcher } from "system/catcher.js"
import { useConfig } from "util/features.js"
import { pushSubscreen } from "util/navigate.js"
import { useServersideConstructor } from 'component/constructor.js'
import { REPLACE_CBC_CommentsWithSort, REPLACE_CBC_PopularComments } from "../../../component/cbcrc/comments";
import { RecirculationSlider } from "../../../component/cbcrc/recirculationCards";
import { useIsAdmin } from "component/admin.js"
import { useGlobalQuestions } from "../../../component/question";
import { Toggle } from "component/form.js"
import { View } from "react-native";
import { REPLACE_CBC_Tag } from "../../../component/cbcrc/tag";
import { Badge } from "../../../component/cbcrc/badge";
import { colorBlack, colorPink, colorTextGrey } from "component/color.js"
import { useGlobalTopics } from "../../../feature/cbcrc/admin/TopicListFeature";
import { TopHeaderBar, TopicHeaderBox, TopicLiveStatus, TopicParticipants } from "../../../component/cbcrc/header";
import { PollWidget } from "../../../feature/cbcrc/poll/poll";
import { RelatedConversations } from "../../../feature/cbcrc/topic/RelatedConversationsFeature";
import { spacings } from "component/constants";

/*
* Topic structure for the Topic of the moment and Ask me anything prototypes
* figma: https://www.figma.com/design/Jt0Nc37N6PP5bpoSDOhxGU/Ask-Me-Anything-%26-Topic-of-the-Week?node-id=15-3&t=ZOjh1kNMLx1lFJSk-0
*/
export const OldTopicStructure = {
    key: 'oldtopic',
    name: 'Old Topic',
    screen: TopicScreen,
    teaser: TopicTeaser,
    subscreens: {
        composer: ({ commentKey, about }) => <ComposerScreen about={about} commentKey={commentKey} intro={<QuestionHeader />} contentType='Public Response' />,
        allQuestions: () => <AllQuestions />,
        admin: () => <TopicAdminScreen />,
        relatedConversationsAdmin: () => <RelatedConversationsAdmin />,
    }, defaultConfig: {
        commentActions: [ActionReply],
        commentRightActions: [ActionReport, ActionEdit],
        commentInputPlaceholder: 'Ask us a question...',
        commentReplyPlaceholder: 'Reply to {authorName}...',
        commentInputLoginAction: '',
        commentPostCheckers: [],
        commentFilters: [],
        commentRankers: [],
        replyFilters: [],
        pageTopWidgets: [],
        pageTopRightWidgets: [],
        pageTopLeftWidgets: [],         
        topBanners: [],
        composerTopBanners: [],
        composerSubtitle: () => 'Public response',
        poll: null,
        noCommentsMessage: 'Be the first to share your thoughts.',
        noMoreCommentsMessage: 'No more questions',
        PollWidget: null,
        visualizationType: 'percent',
        teaser: null,
        adminScreen: null,
        topicScreen: null,
        bylineTags: [(persona) => { 
            if (persona.titledWriter?.title)  return <REPLACE_CBC_Tag color={colorPink} textColor={colorBlack} type="tiny" text={persona.titledWriter.title} />
        }],
        profilePhotoLayers: [(persona) => { 
            if (persona.titledWriter?.badgeImageUrl) return <Badge imageUrl={persona.titledWriter.badgeImageUrl} />
        }],
    },
    instance: []
}


function TopicTeaser(params) {
    useServersideConstructor();
    const { teaser } = useConfig();
    if (teaser) {
        return React.createElement(teaser, params);
    }
    return <UtilityText label='No teaser defined' />
}

function TopicAdminScreen() {
    const { adminScreen } = useConfig();
    if (adminScreen) {
        return React.createElement(adminScreen);
    } else {
        return <PadBox horiz={20} vert={20}><UtilityText label='No admin screen defined. Use the feature selector to enable a feature.' /></PadBox>
    }
}

function TopicScreen() {
    const instanceKey = useInstanceKey();
    const topics = useGlobalTopics();
    const topic = topics.filter(({ key }) => key === instanceKey)[0];
    const datastore = useDatastore();
    const conversationStarter = useGlobalProperty('conversationStarter');
    const personas = useCollection('persona');
    const name = useGlobalProperty('name');
    const nextTopicPoll = useGlobalProperty('nextTopicPoll');
    const poll = useGlobalProperty('poll');
    let comments = useCollection('comment', {filter: {about: null, replyTo: null}, sortBy: 'time'});
    // const personaKey = usePersonaKey();
    // const myQuestion = comments.find(comment => comment.from == personaKey && comment.replyTo == null);
    const status = useGlobalProperty('status');
    const live = status === 'live';
    const startAt = useGlobalProperty('startAt');
    const endAt = useGlobalProperty('endAt');
    const relatedContents = useGlobalProperty('relatedContents');

    const [notificationSubscribed, setNotificationSubscribed] = useState(false);

    return <ConversationScreen>
        {/* {topBanners?.map((Banner, i) => <Banner key={i} />)} */}
        <TopicHeaderBox status={<TopHeaderBar tag={<TopicLiveStatus live={live} />} notificationSubscribed={notificationSubscribed} notificationOnPress={() => { setNotificationSubscribed(!notificationSubscribed) }} />} preHeading='Topic of the moment' title={name} content={<TopicParticipants live={live} count={personas?.length - 1 || 0} startAt={startAt} endAt={endAt} />} />
        <Pad size={spacings.sm} />
        <PadBox horiz={spacings.lg} vert={spacings.xl2}>
            {(PollWidget && poll) ?
                <PollWidget
                    pollKey={'topicPoll'}
                    question={poll.question}
                    options={poll.options}
                    pollType={'MultipleChoice'}
                /> :
                <UtilityText label='No poll defined' />
            }
            <Pad size={spacings.xl2 * 2} />
            <View role="region">
                <Heading type="large" weight="medium" text={conversationStarter} />
                <Pad size={spacings.xl} />
                <CommentsInput />
                <Pad size={spacings.sm} />
                <Paragraph color={colorTextGrey} label='Your question will be considered for future content pieces. Please note, we may not be able to answer all questions.' />
                <Pad size={spacings.xl2} />
                <Heading label='Popular questions' />
                <Pad size={spacings.sm} />
                <Catcher>
                    <REPLACE_CBC_PopularComments canPost={false} />
                </Catcher>
                {comments?.length > 4 && (
                    <>
                        <Pad size={spacings.lg} />
                        <Center>
                            <CTAButton type='primary' label='View all questions' 
                                onPress={datastore.needsLogin(() => pushSubscreen('allQuestions', {}))} />
                        </Center>
                    </>
                    )}
            </View>
            <Pad size={spacings.xl2 * 2} />
            <RelatedConversations />
            <Pad size={spacings.xl2 * 2} />
            <View role="region">
                <Heading type="large" weight="medium" label='Need more information to make up your mind?' />
                <Pad size={spacings.xl} />
                <RecirculationSlider cardsData={relatedContents} cardType='content' controls />
            </View>
            <Pad size={spacings.xl2 * 2} />
            {(PollWidget && nextTopicPoll) ?
                <>
                    <Pad size={spacings.xl2} />
                    <PollWidget
                        pollKey={'upcomingTopicPoll'}
                        question={nextTopicPoll.question}
                        options={nextTopicPoll.options}
                        pollType={'MultipleChoiceOther'}
                        otherOptionValue={nextTopicPoll.otherOptionValue}
                        otherOptionPlaceholder={nextTopicPoll.otherOptionPlaceholder}
                    />
                </> :
                <UtilityText label='No upcoming topic poll defined' />
            }
            {/* TODO: implement the previous topics recirculation feature */}
            {/* <Heading type="large" weight="medium" label='Related topics demo' />
            <Pad size={spacings.xl} />
            <RecirculationSlider cardsData={relatedTopics} cardType='topic' /> */}
        </PadBox>
    </ConversationScreen>
}

function QuestionHeader() {
    const conversationStarter = useGlobalProperty('conversationStarter');

    return <PadBox horiz={spacings.lg} vert={spacings.xl2}>
        <Heading type="large" weight="medium" text={conversationStarter} />
    </PadBox>
}

function AllQuestions() {
    return <PadBox vert={spacings.xl2}>
        <Catcher>
            <BasicComments canPost={false} />
        </Catcher>
    </PadBox>
}

function Question({ question }) {
    const datastore = useDatastore();
    const selectedQuestion = useObject('relatedConversations', question.key);
    const isChosen = selectedQuestion && !selectedQuestion.deleted;

    async function onChange(selected) {
        await datastore.callServerAsync('topics', selected ? 'selectRelatedQuestion' : 'unselectRelatedQuestion', {questionKey: question.key });
    }

    return <PadBox bottom={20} horiz={20}>
        <Card>
            <HorizBox spread>
                <UtilityText type='small' paragraph text={question.text ?? question.question} weight="medium" />
                <Pad />
                <Toggle lable='Selected' value={isChosen} onChange={onChange} />
            </HorizBox>
        </Card>
    </PadBox>
}

export function RelatedConversationsAdmin() {
    const questions = useGlobalQuestions();
    const [search, setSearch] = useState('');
    const filteredQuestions = questions?.filter(question => !search || question.question?.toLowerCase().includes(search.toLowerCase()));

    return (
        <ConversationScreen>
            <Pad />
            <PadBox horiz={20}><Heading label='Choose related conversations for this Topic' /></PadBox>
            <Pad />
            <Separator />
            <Pad />
            {/* TODO need a new "Question component?" */}
            {filteredQuestions?.map(question =>
                <Catcher key={question.key}><Question question={question} /></Catcher>
            )}
        </ConversationScreen>
    )
}