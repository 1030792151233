import { StyleSheet, View } from "react-native";
import { ModDashboardQueue, ModerationFilters } from "./moddashboardqueue";
import { useEffect, useState } from "react";
import { ThinTag } from "../button";
import { BlockConfirmationModal, UnblockConfirmationModal } from "./judgementcard";
import { useDatastore, useModulePublicData, usePersonaKey, usePersonaObject } from "../../../util/datastore";
import { colorGreyBorder, colorGreyPopupBackground, colorPink, colorTextGrey, colorWhite } from "../../color";
import { HorizBox, LoadingScreen, Pad, PadBox } from "../../basics";
import { Heading, Paragraph, UtilityText } from "../../text";
import { LetterFace, ProfilePhoto } from "../../people";
import { CTAButton } from "../../button";
import { getQueueSubsetCounts, useFilteredQueuesOfUser } from "./moddashboardqueuehooks";

export function useUserProfileModerationData({userId}) {
    const [userProfileData, setUserProfileData] = useState(null);
    const datastore = useDatastore();

    const timestampLastModerationAction = useModulePublicData("moderation", ["user", userId, "timeLastModerationAction"]);
    // Fetches user profile data whenever new mod tasks come in or when mod tasks change status (e.g. from awaiting decision to rejected). This way the counters on the user profile will be updated in realtime.
    useEffect(() => {
        const fetchUserProfileData = async () => {
            if (!userId) {
                return;
            }

            const userProfileDataResult = await datastore.callServerAsync("moderationZdf", "getUserProfileData", { key: userId});
            // We need to stringify userProfileData and userProfileDataResult to compare them because they are non-primitive data types. A regular comparison will always say they're different even when they have the same value.
            if (userProfileDataResult && JSON.stringify(userProfileDataResult) !== JSON.stringify(userProfileData)) {
                setUserProfileData(userProfileDataResult);
            }
        };

        fetchUserProfileData();
    }, [timestampLastModerationAction]);

    return userProfileData
}

export function ModDashboardUserProfile({userId}) {
    return <View style={ModDashboardUserProfileStyle.page}>
            <Heading label={"User Profile"} level={1} />
            <ModDashboardUserProfileInformation userId={userId}/>
            <View>
                <Pad size={24} />
                <Heading label={"All comments"} />
                <ModDashboardQueue
                    structureKey={"profile"}
                    instanceKey={userId}
                    useAllFilteredQueues={useFilteredQueuesOfUser}
                    useQueueCounts={getQueueSubsetCounts}
                    additionalFilters={[ModerationFilters.Warned]}
                />
            </View>
        </View>
}

export function ModDashboardUserProfileInformation({ userId }) {
    const datastore = useDatastore();
    const currentModeratorKey = usePersonaKey();
    const currentModeratorPersona = usePersonaObject(currentModeratorKey);

    const timestampLastModerationAction = useModulePublicData("moderation", ["user", userId, "timeLastModerationAction"]);

    const userProfileModerationData = useUserProfileModerationData({userId})
    const [warningCount, setWarningCount] = useState(0);
    const [isBlocked, setIsBlocked] = useState(false);
    const [blockedByName, setBlockedByName] = useState("");
    const [showBlockConfirmationModal, setShowBlockConfirmationModal] = useState(false);
    const [showUnblockConfirmationModal, setShowUnblockConfirmationModal] = useState(false);

    const blockExplanationText =
        isBlocked
            ? "User has already been warned " + warningCount + " times and was blocked by " + blockedByName
            : warningCount < 2
                ? "A user must be warned 2 times before they can be blocked"
                : "User has already been warned " + warningCount + " times";


    // TODO: Is there a nicer way to get realtime updates without storing suspension info in module-public?
    useEffect(() => {
        const fetchSuspensionInfo = async () => {
            if (!userId) {
                return;
            }

            const suspensionInfoResult = await datastore.callServerAsync("moderationZdf", "getUserSuspensionInfo", {
                key: userId,
            });

            const warningCountResult =
                suspensionInfoResult && suspensionInfoResult.warnings
                    ? Object.keys(suspensionInfoResult.warnings).length
                    : 0;
            const isBlockedResult =
                suspensionInfoResult && suspensionInfoResult.isBlocked ? suspensionInfoResult.isBlocked : false;
            const blockedByNameResult =
                suspensionInfoResult && suspensionInfoResult.blockedByName ? suspensionInfoResult.blockedByName : "";

            if (warningCountResult !== warningCount) {
                setWarningCount(warningCountResult);
            }

            if (isBlockedResult !== isBlocked) {
                setIsBlocked(isBlockedResult);
            }

            if (blockedByNameResult !== blockedByName) {
                setBlockedByName(blockedByNameResult);
            }
        };

        fetchSuspensionInfo();
    },[timestampLastModerationAction]);

    async function onBlock() {
        const isBlocked = await datastore.callServerAsync("moderationZdf", "getIsUserBlocked", { key: userId });
        if (!isBlocked) {
            await datastore.callServerAsync("moderationZdf", "setIsUserBlocked", {
                key: userId,
                isBlocked: true,
                blockedByKey: currentModeratorKey,
                blockedByName: currentModeratorPersona.name,
            });
        }

        setShowBlockConfirmationModal(false);
    }

    async function onUnblock() {
        const isBlocked = await datastore.callServerAsync("moderationZdf", "getIsUserBlocked", { key: userId });
        if (isBlocked) {
            await datastore.callServerAsync("moderationZdf", "setIsUserBlocked", {
                key: userId,
                isBlocked: false,
                blockedByKey: null,
                blockedByName: null,
            });
        }

        setShowUnblockConfirmationModal(false);
    }

    if (!userProfileModerationData || !userProfileModerationData.preview) {
        return <LoadingScreen />;
    }
    const preview = userProfileModerationData?.preview;

    return <View style={ModDashboardUserProfileStyle.informationBox}>
        <HorizBox center>
            {preview.photoUrl ? <ProfilePhoto type="huge" photo={preview.photoUrl} /> :
                <LetterFace type="huge" name={preview.name} hue={preview.hue} />}
            <Pad size={16} />
            <View style={{ gap: 2 }}>
                <HorizBox center>
                    <Heading text={preview.name} />
                    {isBlocked && (
                        <>
                            <Pad size={8} />
                            <ThinTag emoji={"🚫"} label="Blocked" backgroundColor={colorPink} />
                        </>
                    )}
                </HorizBox>
                <HorizBox>
                    <UtilityText color={colorTextGrey} label={"Member since "} />
                    <UtilityText
                        color={colorTextGrey}
                        text={new Date(preview.memberSince).getFullYear().toString()}
                    />
                </HorizBox>
            </View>
        </HorizBox>
        <PadBox horiz={24}>
            <ModDashboardUserStatistics userProfileData={userProfileModerationData} />
        </PadBox>
        <View style={ModDashboardUserProfileStyle.settingsBox}>
            <Heading label={"User settings"} />
            <View>
                <View style={{ width: 234 }}>
                    {warningCount < 2 && !isBlocked && <CTAButton label={"Block user"} wide disabled />}
                    {warningCount >= 2 && !isBlocked && (
                        <>
                            <CTAButton
                                wide
                                type="delete"
                                label={"Block user"}
                                onPress={() => setShowBlockConfirmationModal(true)}
                            />
                            {showBlockConfirmationModal && (
                                <BlockConfirmationModal
                                    onBlock={onBlock}
                                    onClose={() => setShowBlockConfirmationModal(false)}
                                />
                            )}
                        </>
                    )}
                    {isBlocked && (
                        <>
                            <CTAButton
                                label={"Unblock user"}
                                wide
                                type="delete"
                                onPress={() => setShowUnblockConfirmationModal(true)}
                            />
                            {showUnblockConfirmationModal && (
                                <UnblockConfirmationModal
                                    onUnblock={onUnblock}
                                    onClose={() => setShowUnblockConfirmationModal(false)}
                                />
                            )}
                        </>
                    )}
                </View>
                <Pad size={16} />
                <UtilityText type="small" text={blockExplanationText} />
            </View>
        </View>
    </View>
}

const ModDashboardUserProfileStyle = StyleSheet.create({
    page: { 
        gap: 24, 
        paddingTop: 58, 
        backgroundColor: colorGreyPopupBackground, 
        flexGrow: 1 },
    informationBox: {
        backgroundColor: colorWhite,
        paddingVertical: 24,
        paddingHorizontal: 20,
        borderRadius: 4,
        gap: 24,
    },
    settingsBox: { 
        borderTopWidth: 1, 
        borderColor: colorGreyBorder, 
        paddingTop: 16, 
        gap: 16 }
});

function CountBox({ count, label }) {
    return (
        <View style={{flex: 1, gap: 8 }}>
            <Heading level={1} text={count.toString()} />
            <Paragraph color={colorTextGrey} label={label} />
        </View>
    );
}

function CountBoxSeparator() {
    return <View style={{flex:0, borderLeftWidth: 1, borderColor: colorGreyBorder, alignSelf: "stretch", marginHorizontal: 8 }}/>;
}

export function ModDashboardUserStatistics({ userProfileData = {}}) {
    if(!userProfileData) {
        return <LoadingScreen/>
    }
    return <View style={{ justifyContent: "space-between", flexDirection: "row"}}>
            <CountBox count={userProfileData.commentCount || 0} label={"comments & replies"} />
            <CountBoxSeparator />
            <CountBox count={userProfileData.flagsGivenCount || 0} label={"reported comments"} />
            <CountBoxSeparator />
            <CountBox count={userProfileData.flagsReceivedCount || 0} label={"flagged comments"} />
            <CountBoxSeparator />
            <CountBox count={userProfileData.rejectedCommentCount || 0} label={"rejected comments"} />
            <CountBoxSeparator />
            <CountBox count={Object.keys(userProfileData.suspension?.warnings || {}).length || 0} label={"user warnings"} />
    </View>
}