import { StyleSheet, View } from "react-native";
import { PollResult, PollSurvey, PollSurveyThenResults, PollSurveyWithTextfield, PollTextResult, PollWidget } from "../../feature/cbcrc/poll/poll";

import { useCollection, useDatastore } from "util/datastore.js";
import { CTAButton } from "component/button.js";
import { STATIC_TOPIC } from '../../structure/cbcrc/topic/static';
import { HeaderBox, Pad } from "component/basics.js";
import { EditorialHeading } from "component/text.js";
import { useState } from "react";
import { spacings } from "component/constants";


const COLLECTION_TOPIC_DB_KEY = "topics";

export function PollScreen() {
    const [myVote, setMyVote] = useState(null);
    const options = ['Cat', 'Dog', 'Komodo Dragon'];
    const votes = ['Dog', 'Dog', 'Dog'];

    function onVote(choice, comment = null) {
        let message =  'You voted for ' + choice;
        if(comment) {
            message += ' with comment ' + comment;
        }
        alert(message);
    }
    function onPollVote(choice, comment = null) {
        setMyVote({ label: choice, ...(comment && { comment }) });
    }
    function onChangeVote() {
        setMyVote(null);
    }

    return <View>
        <EditorialHeading level={1} strong text='PollSurvey' />
        <PollSurvey question='What is your favorite animal?' options={options} onVote={onVote} />
        <Pad />
        <EditorialHeading level={1} strong text='PollSurveyWithTextfield' />
        <PollSurveyWithTextfield question='What is your favorite animal?' options={options} onVote={onVote} otherOptionValue={'Other'} otherOptionPlaceholder="Placeholder value" />
        <EditorialHeading level={1} strong text='PollResult' />
        <PollResult options={options} votes={['Cat', 'Dog', 'Dog']} myVote='Cat' visualizationType='percent' />
        <PollResult options={options} votes={['Dog', 'Dog', 'Dog']} myVote='Dog' visualizationType='percent' />
        <EditorialHeading level={1} strong text='PollTextResult' />
        <PollTextResult myVote={'Dog'}/>

        <Pad />
        <EditorialHeading level={1} strong text='PollSurveyThenResults' />
        <PollSurveyThenResults
            question='What is your favorite animal?' options={options}
            onVote={onPollVote} onChangeVote={onChangeVote}
            votes={[...votes, myVote?.label]} myVote={myVote?.label} isSubmitted={myVote} tagline='Vote now!' resultline='Results' visualizationType='percent' />
        
        <EditorialHeading level={1} strong text='PollSurveyThenResults TextResult' />
        <PollSurveyThenResults 
            question='What is your favorite animal?' options={options} 
            pollType='MultipleChoiceOther'
            onVote={onPollVote} 
            onChangeVote={onChangeVote}
            otherOptionValue={'Other'}
            otherOptionPlaceholder={'Other...'}
            votes={[...votes, myVote?.label]} myVote={myVote?.label} comment={myVote?.comment} isSubmitted={myVote} tagline='Vote now!' resultline='Results' visualizationType='percent' />
    </View>
}

export function PollWidgetScreen() {
    const datastore = useDatastore();

    const topic = useCollection(COLLECTION_TOPIC_DB_KEY);
    let poll = null;
    if (topic[0]) poll = topic[0].poll;

    return <View style={DemoStyle.demo}>
        <EditorialHeading level={1} strong text={'PollWidget'} />
        <Pad size={spacings.md} />
            {poll && <PollWidget question={poll.question} tagline={poll.tagline} resultline={poll.resultline} options={poll.options} />}
        <Pad size={spacings.md} />
        <HeaderBox>
            <EditorialHeading level={1} strong text={'Demo poll component : Voting on next topic'} />
        </HeaderBox>
        <Pad size={spacings.md} />
        <View>
            <CTAButton text='init data poll' onPress={onAdd} />
        </View>
    </View>

    async function onAdd() {
        datastore.addObject(COLLECTION_TOPIC_DB_KEY, STATIC_TOPIC);
    }

}


const DemoStyle = StyleSheet.create({
    demo: {
        paddingHorizontal: 20,
        paddingVertical: 15,
    },
    titledWiterBox: {
        boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.10)',
        padding: 20,
        borderRadius: 12,
    }
})