import { StyleSheet, View } from "react-native";
import { closeSidebar, makeUrl } from "../util/navigate";
import { firebaseSignOut, useFirebaseUser } from "../util/firebase";
import { useDatastore, useGlobalProperty, useInstanceKey, useMyRoles, usePersonaKey, usePersonaObject, useSessionData, useSiloKey, useStableCallback, useStructureKey } from "../util/datastore";
import { useEffect, useState } from "react";
import { Byline } from "./people";
import { BreadCrumb, CTAButton, Popup, TextButton, BetaTag, Tag } from "./button";
import { HorizBox, Pad, PadBox, Separator } from "./basics";
import { getFeatureBlocks, useConfig, useEnabledFeatures } from "../util/features";
import { defaultFeatureConfig } from "../feature";
import { Catcher } from "../system/catcher";
import { getIsInPsiNativeAppWebview, getIsInSidebar, historyGetState } from "../platform-specific/url";
import { useHasCapability, useIsAdmin } from "./admin";
import { CircleCount, UtilityText, WebLinkTextButton } from "./text";
import { AccordionField, RadioGroup, RadioOption, Toggle } from "./form";
import { colorGreyBorder, colorGreyPopupBackground, colorPurpleBackground, colorTextGrey } from "./color";
import { ChevronDown, ChevronUp, Close, ArrowLeft } from '@carbon/icons-react';
import { useModulePublicData } from "../util/datastore";
import { requestAppLogin } from "util/webview";
import { useTranslation } from "./translation";
import { toBool } from 'util/util';

export function TopBar() {
    const s = TopBarStyle;
    const instanceKey = useInstanceKey();
    const structureKey = useStructureKey();
    const toolbarAction = useSessionData('toolbarAction');
    const toolbarSecondaryAction = useSessionData('toolbarSecondaryAction');
    const datastore = useDatastore();
    const isLogin = structureKey == 'login';
    const {topBarHelpBubbles} = useConfig();
    const fbUser = useFirebaseUser();
    // console.log('fbUser email', fbUser?.email);
    return <View style={s.outer}>
        <View style={s.topBox}>        
            <View style={s.leftRow}>    
                {historyGetState() ? 
                    <BreadCrumb testID='back' ariaLabel='Back' icon={ArrowLeft} iconProps={{size:32}} onPress={() => datastore.goBack()} />
                : (getIsInSidebar() || getIsInPsiNativeAppWebview()) ?
                    <BreadCrumb testID='close' ariaLabel='Close'  icon={Close} iconProps={{size:32}}  onPress={closeSidebar} />                
                : null
                }
            </View>
            <Catcher>
                <HorizBox center>
                    {toolbarSecondaryAction &&
                        <CTAButton size='compact' 
                            icon={toolbarSecondaryAction.icon} label={toolbarSecondaryAction.label} 
                            type={toolbarSecondaryAction.type} disabled={toolbarSecondaryAction.disabled} 
                            onPress={toolbarSecondaryAction.onPress} />
                    }
                    {toolbarAction ? 
                        <PadBox horiz={12}><CTAButton size='compact' label={toolbarAction.label} disabled={toolbarAction.disabled} onPress={toolbarAction.onPress} /></PadBox>
                    : 
                        instanceKey && !isLogin && <UserInfo />
                    }
                </HorizBox>
            </Catcher>
        </View>
        <PadBox horiz={12}>
            {topBarHelpBubbles?.map((Bubble,i) => <Bubble key={i} />)}
        </PadBox>
    </View>
}

const TopBarStyle = StyleSheet.create({
    outer: {
        zIndex: 900
    },
    topBox: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 16, 
        justifyContent: 'space-between',
        borderBottomColor: colorGreyBorder, 
        borderBottomWidth: StyleSheet.hairlineWidth,
        backgroundColor: 'white',
        height: 56
    },
    leftRow: {
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 1
    },
    oneLineTitle: {
        fontSize: 18, fontWeight: 'bold',
        marginVertical: 8,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    twoLineTitle: {
        fontSize: 15, fontWeight: 'bold',
        flexShrink: 1
    },
    subtitle: {
        fontSize: 13, color: '#666'
    },
    titleBox: {
        marginVertical: 2,
        marginLeft: 4,
        flexShrink: 1
    }
})


export function FeatureToggles() {
    const structureKey = useStructureKey();
    const featureBlocks = getFeatureBlocks(structureKey);
    const hasDevToggles = useHasCapability('features/toggle-experimental');
    if (!featureBlocks) return null;
    return <View>
        <Pad />
        <Separator />
        <View style={{width: 300}} />
        <Pad size={20} />
        <UtilityText type='tiny' text={hasDevToggles ? 'Basic Features' : 'Features Enabled Here'} caps />
        <Pad size={12} />
        {featureBlocks.map((featureBlock,i) => 
            <Catcher key={i}>
                <FeatureTreeNode featureBlock={featureBlock} />
            </Catcher>
        )}
        {hasDevToggles && <View>
            <Pad size={20} />
            <Separator />
            <Pad />
            <UtilityText type='tiny' text='Developer Features' caps />
            <Pad size={12} />
            {featureBlocks.map((featureBlock,i) => 
                <Catcher key={i}>
                    <FeatureTreeNode showDev featureBlock={featureBlock} />
                </Catcher>
            )}            
        </View>}
    </View>
}

export function featureBlockContainsVisibleChild({featureBlock, showDev=false}) {
    if (featureBlock.section) {
        return featureBlock.features.some(fb => featureBlockContainsVisibleChild({featureBlock: fb, showDev}));
    } else if (featureBlock.composite) {
        return toBool(featureBlock.parent.dev) == showDev || featureBlock.parent.both ||
        featureBlock.features.some(fb => featureBlockContainsVisibleChild({featureBlock: fb, showDev}));
    } else if (featureBlock.chooseOne) {
        return featureBlock.features.some(fb => featureBlockContainsVisibleChild({featureBlock: fb, showDev}));
    } else if (featureBlock.name) {
        return showDev == toBool(featureBlock.dev) || featureBlock.both;
    } else {
        return false;
    }
}

export function FeatureTreeNode({showDev=false, featureBlock}) {
    const s = FeatureTreeNodeStyle;
    const enabledFeatures = useEnabledFeatures();
    function isEnabled(fb) {
        if (toBool(fb.dev) !== showDev) return false;
        return enabledFeatures?.[fb.key] || enabledFeatures?.[fb.parent?.key]
    }
    if (!featureBlockContainsVisibleChild({featureBlock, showDev})) return null;

    if (featureBlock.section) {
        const enabledCount = featureBlock.features.filter(isEnabled).length;
        const titleContent = <HorizBox center spread>
            {featureBlock.level == 2 ? 
                <UtilityText type='tiny' caps weight='strong' text={featureBlock.label} />
            :
                <UtilityText weight='medium' text={featureBlock.label} />
            }
            <Pad size={8} />
            {enabledCount ? <CircleCount count={enabledCount} /> : null}
        </HorizBox>
        return <AccordionField testID={featureBlock.label} titleContent={titleContent}>
            {featureBlock.features.map((fb,i) => 
                <Catcher key={i}>
                    <FeatureTreeNode showDev={showDev} featureBlock={fb} />
                </Catcher>
            )}
        </AccordionField>
    } else if (featureBlock.composite) {
        const enabled = enabledFeatures?.[featureBlock.parent.key] ?? false;
        if (!enabled) {
            return <FeatureToggle showDev={showDev} feature={featureBlock.parent} />
        } else {
            return <View>
                <FeatureToggle showDev={showDev} feature={featureBlock.parent} />
                <View style={s.subFeatures} >
                    {featureBlock.features.map((fb,i) => 
                        <Catcher key={i}>
                            <FeatureTreeNode showDev={showDev} featureBlock={fb} />
                        </Catcher>
                    )}
                </View>
            </View>
        }
    } else if (featureBlock.chooseOne) {
        return <ChooseOneFeature text={featureBlock.label} featureList={featureBlock.features} />
    } else if (featureBlock.name) {
        return <FeatureToggle showDev={showDev} feature={featureBlock} />
    } else {
        console.error('Unknown feature node', featureBlock);
        return null;
    }
}
const FeatureTreeNodeStyle = StyleSheet.create({
    subFeatures: {
        backgroundColor: colorGreyPopupBackground,
        paddingHorizontal: 12,
        borderRadius: 8
    }
})

function ChooseOneFeature({text, featureList}) {
    const enabledFeatures = useEnabledFeatures();
    const firstEnabled = featureList.find(f => enabledFeatures?.[f.key])?.key;
    const [chosenFeature, setChosenFeature] = useState(firstEnabled);
    const datastore = useDatastore();
    function onChange(value) {
        const newFeatures = {
            ...enabledFeatures,
            [chosenFeature]: false,
            [value]: true
        }
        datastore.setGlobalProperty('features', newFeatures);
        setChosenFeature(value);
    }
    return <View>
        {text && <PadBox vert={12}><UtilityText weight='strong' caps type='tiny' text={text} /></PadBox>}
        <RadioGroup value={chosenFeature} onChange={onChange}>
            {featureList.map(f =>
                <RadioOption key={f.key} radioKey={f.key} text={f.name} />
        )}
        </RadioGroup>
    </View>
}

function FeatureToggle({feature}) {
    const structureKey = useStructureKey();
    const defaultFeatures = defaultFeatureConfig[structureKey] ?? {};
    const defaultState = defaultFeatures[feature.key] ?? false;
    
    const features = useGlobalProperty('features');
    const enabled = features?.[feature.key] ?? defaultState;
    const datastore = useDatastore();

    if (feature.parentFeature && !features?.[feature.parentFeature]) return null;

    function onToggle() {
        const newFeatures = {
            ...features,
            [feature.key]: !enabled
        }
        datastore.setGlobalProperty('features', newFeatures);
    }

    return <Toggle text={feature.name} spread value={enabled || false} onChange={onToggle} />
}

function UserEmailAndRoles() {
    const fbUser = useFirebaseUser();
    const roles = useMyRoles();
    return <View>
        {roles?.length > 0 && 
            <PadBox bottom={4}>
                <HorizBox gap={4}>
                    {roles.map(role => <Tag key={role} type='tiny' label={role} color={colorPurpleBackground} />)}
                </HorizBox>
            </PadBox>
        }
        <UtilityText type='small' color={colorTextGrey} text={fbUser?.email} />
    </View>
}

function UserInfo() {
    const s = UserInfoStyle;
    const personaKey = usePersonaKey();
    const persona = usePersonaObject(personaKey);
    const [hover, setHover] = useState(false);
    const [shown, setShown] = useState(false);
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const siloKey = useSiloKey();
    const showBetaTag = useModulePublicData('isBeta')

    function popup() {
        return <View>
            <UserEmailAndRoles />
            <Pad />
            <TextButton type='small' onPress={() => datastore.gotoInstance({structureKey:'profile', instanceKey: personaKey})} label='Profile' /> 
            <Pad />
            <TextButton type='small' onPress={() => datastore.gotoInstance({structureKey:'account', instanceKey: 'one'})} label='Account' />

            {isAdmin && <PadBox top={20}>
                <WebLinkTextButton url={makeUrl(['admin','one'])} text='Admin' />
            </PadBox>}  
            
            {!getIsInPsiNativeAppWebview() && <><Pad /><TextButton type='small' onPress={() => datastore.signOut()} label='Log out' /></>}
            {isAdmin && <Catcher><FeatureToggles /></Catcher>}
        </View>
    }

    function onLogin() {
        if(getIsInPsiNativeAppWebview()) {
            requestAppLogin();
        } else {
            datastore.gotoInstance({structureKey: 'login', instanceKey: 'one'});
        }
    }

    if (personaKey) {
        return <Popup testID='account-menu' popupContent={popup} setHover={setHover} setShown={setShown} popupStyle={s.popup}>
            <PadBox vert={6} right={20}>
                <HorizBox center>
                    {showBetaTag && <PadBox right={12}> 
                        <BetaTag /> 
                    </PadBox>}                    
                    <Byline userId={personaKey} clickable={false} name={persona.name} underline={hover} showMetadata={false} />
                    <Pad size={8} />
                    {shown ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                </HorizBox>
            </PadBox>
        </Popup>
    } else {        
        return <HorizBox center>
            {showBetaTag && <PadBox right={12}> 
                <BetaTag /> 
            </PadBox>} 
            <CTAButton type='secondary' onPress={onLogin} size='compact' label='Log in' />
            <Pad size={20} />
        </HorizBox>     
    }
}

const UserInfoStyle = StyleSheet.create({
    title: {
        fontSize: 18, fontWeight: 'bold',
    },
    right: {
        marginLeft: 12
    },
    popup: {
        marginRight: 20,
    }
});


// We need to use useStableCallback because otherwise
// we either update setSessionData every time the callback changes, which 
// risks creating dependency loops, or we don't update the button when
// the callback changes, and the button doesn't work.
export function TopBarActionProvider({label, secondary=false, type, icon, disabled, onPress}) {
    const datastore = useDatastore();
    const stableOnPress = useStableCallback(onPress);
    const property = secondary ? 'toolbarSecondaryAction' : 'toolbarAction';
    useEffect(() => {
        datastore.setSessionData(property, {label, disabled, type, icon, onPress: stableOnPress});
        return () => {
            datastore.setSessionData(property, null);
        }
    }, [label, disabled, stableOnPress]);
    return null;
}

