import { ConversationScreen, HeaderBox, HorizBox, Pad, WindowTitle } from "component/basics.js"
import { colorTealBackground, colorWhite } from "component/color.js"
import { Heading, UtilityText } from "component/text.js"
import { useGlobalProperty } from "util/datastore.js"
import { useConfig } from "util/features.js"
import React from "react";
import { spacings } from "component/constants"


export const TopicStructure = {
    key: 'topic',
    name: 'Topic',
    screen: TopicScreen,
    teaser: TopicTeaser,
    hasFocusTrap: true,
    defaultConfig: {
        topicDescriptionWidget: TopicDescription,
        topicContentWidgets: [],
        topicTopWidgets: [],
        topicBottomWidgets: [],
        topicHeaderLeftWidgets: [],
        topicHeaderRightWidgets: [],
        topicHeaderBottomWidgets: [],
        teaser: null,
        topBarHelpBubbles: [],
        bylineTags: [],
        profilePhotoLayers: [],
        composerTopWidgets: [],
        commentMiddleWidgets: [],
        commentAboveWidgets: [],
        replyAboveWidgets: [],
        replyBoosters: [],
        commentBoosters: [],
        headerBackgroundColor: colorTealBackground,
        headerFontColor: colorWhite,
    }   
}

function TopicDescription() {
    return <UtilityText color={colorWhite} label='Discussion topic' />
}

export function TopicTeaser() {
    const {teaser} = useConfig();
    if (teaser) {
        return React.createElement(teaser);
    } else {
        return <UtilityText label='No teaser defined' />
    }
}

function TopicHeader() {
    const name = useGlobalProperty('name');
    const {topicHeaderLeftWidgets, topicHeaderRightWidgets, 
    topicHeaderBottomWidgets, topicDescriptionWidget, headerBackgroundColor, headerFontColor } = useConfig();
    return <HeaderBox backgroundColor={headerBackgroundColor}>
        <WindowTitle title={name} />
        {(topicHeaderLeftWidgets?.length || topicHeaderRightWidgets?.length) ? <Pad size={12} /> : null}
        <HorizBox spread center>
            <HorizBox center>
                {topicHeaderLeftWidgets.map((Widget, index) => <Widget key={index} />)}
            </HorizBox>
            <HorizBox center>
                {topicHeaderRightWidgets.map((Widget, index) => <Widget key={index} />)}
            </HorizBox>
        </HorizBox>
        {(topicHeaderLeftWidgets?.length || topicHeaderRightWidgets?.length) ? <Pad size={spacings.xl} /> : <Pad size={12} />}
        {React.createElement(topicDescriptionWidget)}
        <Pad size={12} />
        <Heading color={headerFontColor} type="large" weight="medium" text={name} />
        {topicHeaderBottomWidgets.map((Widget, index) => <Widget key={index} />)}
        <Pad size={12} />
    </HeaderBox>
}


function TopicScreen() {
    const {topicTopWidgets, topicContentWidgets, topicBottomWidgets} = useConfig();
    return <ConversationScreen>
        <TopicHeader />
        {topicTopWidgets.map((Widget, index) => <Widget key={index} />)}
        {topicContentWidgets.map((Widget, index) => <Widget key={index} />)}
        {topicBottomWidgets.map((Widget, index) => <Widget key={index} />)}
        <Pad size={80} />
    </ConversationScreen>
}


