import { StyleSheet, View } from "react-native";
import { useEffect, useMemo, useRef, useState } from "react";
import { ModDashboardCommentCard } from "./moddashboardcommentcard";
import { JudgementCard } from "./judgementcard";
import { REPLACE_ZDF_FilterButton } from "../button";
import { useModerationAuthorsByIds } from "../moderation";
import { useFilteredQueueAllInstances, useFilteredQueueCurrentInstance, useFilteredQueues, useModQueue, useGlobalQueueCounts } from "./moddashboardqueuehooks";
import { useDatastore, useInstanceKey, useModulePublicData, usePersonaKey, useSessionData  } from "../../../util/datastore";
import { Center, HorizBox, Pad, PadBox } from "../../basics";
import { Heading, UtilityText } from "../../text";
import { colorGreyPopupBackground, colorTextGrey, colorWhite } from "../../color";
import { SpacedArray } from "../../../system/demo";
import { CTAButton, DropDownSelector } from "../../button";
import { gotoInstance } from "../../../util/navigate";
import { Banner } from "../../banner";
import { generateRandomKey } from "../../../util/util";
import { useInstanceParams } from "../../../util/params";


export const ModerationFilters = {
    AwaitingDecision: { id: "awaiting_decision", text: "Awaiting decision", emoji: "🔍" },
    AutomaticallyApproved: { id: "auto_approved", text: "Automatically approved", emoji: "✅" },
    HumanApproved: { id: "human_approved", text: "Humanly approved", emoji: "🕵️" },
    Rejected: { id: "rejected", text: "Humanly rejected", emoji: "⛔️" },
    Warned: { id: "warned", text: "Warnings", emoji: "🚦" },
};

const ModerationFiltersDefault = [
    ModerationFilters.AwaitingDecision,
    ModerationFilters.AutomaticallyApproved,
    ModerationFilters.HumanApproved,
    ModerationFilters.Rejected,
]


export function ModDashboardQueueGeneral() {
    return (
        <View style={ModDashboardQueueStyle.generalQueueOuterContainer}>
            <PadBox vert={80}>
                <Heading level={1} label={"Comments"} />
                <ModDashboardQueue/>
            </PadBox>
        </View>
    );
}

const ModDashboardQueueStyle = StyleSheet.create({
    generalQueueOuterContainer: {
        flex: 1,
        flexShrink: 1,
        height: "100%",
    },
    queueOuterContainer: {
        flexGrow: 1, 
        flexShrink: 1
    },
    loadNewItemsButton: {
        position: "relative", 
        zIndex: 10, 
        width: "100%"
    }
});

export function ModDashboardQueue({
    structureKey,
    instanceKey,
    amountItems = 20,
    additionalFilters = [],
    useAllFilteredQueues = useFilteredQueues,
    useQueueCounts,
}) {
    const [selectedFilters, setSelectedFilters] = useState([ModerationFilters.AwaitingDecision]);
    const [sortingOrder, setSortingOrder] = useState("oldest");
    const datastore = useDatastore();

    const [limit, setLimit] = useState(amountItems);
    const [startTime, setStartTime] = useState(Date.now());

    const useSingleFilteredQueue = useMemo(() => {
        if (structureKey && instanceKey) {
            return useFilteredQueueCurrentInstance;
        } else {
            return useFilteredQueueAllInstances;
        }
    }, [structureKey, instanceKey]);

    const useCounts = useMemo(() => {
        return structureKey && instanceKey ? null : useGlobalQueueCounts;
    }, [structureKey, instanceKey]);

    const { queue, newItemsToLoad, moreItemsToLoad, queueCounts } = useModQueue({
        filterOptions: selectedFilters,
        sortingOrder,
        limit,
        startTime,
        structureKey,
        instanceKey,
        useAllFilteredQueues,
        useSingleFilteredQueue,
        useQueueCounts: useQueueCounts || useCounts,
    });

    function toggleFilter({ filterOption }) {
        // Remove filter from list
        if (selectedFilters.includes(filterOption)) {
            setSelectedFilters(selectedFilters.filter((selectedFilter) => selectedFilter !== filterOption));
        }
        // Add filter to list
        else {
            setSelectedFilters([...selectedFilters, filterOption]);
        }

        // Reset the search parameters
        setLimit(amountItems);
        setStartTime(Date.now());
    }

    const loadNewItems = () => {
        setLimit(limit + newItemsToLoad.length);
        setStartTime(Date.now());
    };

    useEffect(() => {
        // Change selection when filters or sorting order changes
        datastore.setSessionData(["modTask-selected-idx"], null);
    }, [selectedFilters, sortingOrder]);

    return (
        <View style={ModDashboardQueueStyle.queueOuterContainer}>
            <View style={{ backgroundColor: colorGreyPopupBackground }}>
                <Pad size={26} />
                <SpacedArray pad={8} horiz>
                    {ModerationFiltersDefault.concat(additionalFilters).map((filter) => (
                        <REPLACE_ZDF_FilterButton
                            key={filter.text}
                            label={filter.text}
                            emoji={filter.emoji}
                            selected={selectedFilters.includes(filter)}
                            count={queueCounts[filter.id] <= 100 ? queueCounts[filter.id].toString() : "100+"}
                            countColor={colorTextGrey}
                            onPress={() => toggleFilter({ filterOption: filter })}
                        />
                    ))}
                </SpacedArray>
                <Pad size={26} />
                <DropDownSelector
                    testID={"sortingOrder"}
                    options={[
                        { key: "oldest", label: "Oldest" },
                        { key: "newest", label: "Newest" },
                    ]}
                    value={sortingOrder}
                    onChange={setSortingOrder}
                />
            </View>
            {/* Preparation for the new items banner, not rendered yet */}
            {(false && newItemsToLoad.length) > 0 && (
                <View style={ModDashboardQueueStyle.loadNewItemsButton}>
                    <View style={{ position: "absolute", left: 0, right: 0 }}>
                        <Center>
                            <CTAButton type="secondary" label={"New items available"} onPress={loadNewItems} />
                        </Center>
                    </View>
                </View>
            )}
            <ModDashboardQueueList queue={queue} sortingOrder={sortingOrder} onChangeSortingOrder={setSortingOrder} />
            {queue.length > 0 && moreItemsToLoad > 0 && (
                <HorizBox>
                    <View style={{ flexGrow: 1 }}>
                        <Center>
                            <Pad size={8} />
                            <CTAButton label={"Load more"} onPress={() => setLimit(limit + amountItems)} />
                        </Center>
                    </View>
                    <PadBox horiz={150} />
                </HorizBox>
            )}
        </View>
    );
}

export function ModDashboardQueueList({ queue=[] }) {
    const modAuthors = useModerationAuthorsByIds(queue.map(modTask => modTask.key))

    return (
        <View>
            {queue.length > 0 ? (
                <>
                    {queue.map((modTask, idx) => (
                        <ModTask
                            key={"modTask" + modTask.key + modTask.judgement}
                            idx={idx}
                            task={modTask}
                            modTaskKey={modTask.key}
                            modAuthor={modAuthors?.[modTask.key]}
                        />
                    ))}
                </>
            ) : (
                <NoModTasksBanner />
            )}
        </View>
    );
}

function useTaskWatchState({modTask, selected}) {
    const expirationTime = 120000; // 2 Minutes
    const datastore = useDatastore()
    const personaKey = usePersonaKey();
    let taskWatchState = useModulePublicData("moderation", ["taskWatchState",modTask.key])
    const taskWatchStateRef = useRef(taskWatchState)
    const temporarySessionId = useSessionData(["moderation", "sessionId"]);

    // Check if Task Watch state is expired (2 minutes)
    // Failsafe in case a taskWatchState is not properly cleaned up (e.g. direct tab close)
    const taskWatchStateValid = ( taskWatchState && (Date.now() - taskWatchState.time) < expirationTime )
    const taskWatchStateHeartBeatRef = useRef(null)

    async function deleteFromReview() {
        if (taskWatchStateRef.current !== null && taskWatchStateRef.current !== undefined) {
            await datastore.callServerAsync("moderationZdf", "unwatchTask", { modTaskID: modTask.key, modID: personaKey })
        }
        clearTimeout(taskWatchStateHeartBeatRef.current)
    }
    async function takeIntoReview() {
        if(!taskWatchStateRef.current || !taskWatchStateValid || taskWatchState.sessionId === temporarySessionId) {
            await datastore.callServerAsync("moderationZdf", "watchTask", { modTaskID: modTask.key, modID: personaKey, sessionId:  temporarySessionId })
        }
    }

    useEffect(()=>{
        taskWatchStateRef.current = taskWatchState

        // Generate a new session id if not already done
        if(!temporarySessionId) {
            datastore.setSessionData(["moderation", "sessionId"], generateRandomKey(10))
            return;
        } 

        if(!taskWatchStateValid && selected) {
            takeIntoReview();
        }

        // If you hold the task watch state, assign it again at half of the expiration time
        if(taskWatchStateValid && !taskWatchStateHeartBeatRef.current && taskWatchState.sessionId === temporarySessionId && selected) {
            taskWatchStateHeartBeatRef.current = setTimeout(()=>{
                takeIntoReview();
            },[expirationTime/2])
        }
        // You do not select the card anymore, you have to unlock the task watch state
        if(!selected && taskWatchStateValid && taskWatchState.sessionId === temporarySessionId) {
            deleteFromReview();
        }

        return()=>{
            if(taskWatchStateHeartBeatRef.current) {
                clearTimeout(taskWatchStateHeartBeatRef.current)
            }
        }
    }, [selected, taskWatchState, temporarySessionId])

    // Release the watchstate on unmount
    useEffect(() => {
        return () => {
            if (taskWatchStateRef.current && taskWatchStateRef.current?.sessionId === temporarySessionId) {
                deleteFromReview()
            }
        }
    }, [])

    return useMemo(()=>{
        return taskWatchStateValid ? {
            ...taskWatchState,
            amIViewing: taskWatchState.sessionId === temporarySessionId,
        } : null
    }, [taskWatchState])
}




export function ModDashboardQueueListItem({ comment, modTask, modAuthor }) {
    const selectedIdx = useSessionData(['modTask-selected-idx']);
    const urlParams = useInstanceParams();
    const isOnThreadsPage = urlParams?.pageId === "page-threads" && !urlParams.userId;
    const isSelected = selectedIdx === modTask.key;
    const datastore = useDatastore();

    const taskWatchState = useTaskWatchState({ modTask: modTask, selected: isSelected })
    const instanceKey = useInstanceKey();

    const onPressView = () => {
        if (isOnThreadsPage) {
            gotoInstance({ 
                structureKey: modTask.structureKey, 
                instanceKey: modTask.instanceKey, 
                params: { "commentID": modTask.key } 
            });
        } else {
            const newParams = {
                ...urlParams,
                pageId: "page-threads",
                threadId: modTask.instanceKey,
                userId: null
            }
            gotoInstance({structureKey: "moddashboard", instanceKey, params: newParams})
        }
    }

    const onClickCard = () => {
        if (isSelected) {
            datastore.setSessionData(["modTask-selected-idx"], null)
        } else {
            datastore.setSessionData(['modTask-selected-idx'], modTask.key);
        }
    }

    const onExpandCard = () => {
        datastore.setSessionData(['modTask-selected-idx'], modTask.key);
    }

    const onCollapseCard = () => {
        datastore.setSessionData(["modTask-selected-idx"], null)
    }

    return (
        <View>
            <Pad size={16} />
            <HorizBox>
                <ModDashboardCommentCard
                    comment={comment}
                    modAuthor={modAuthor}
                    modTask={modTask}
                    highlighted={isSelected}
                    onPressView={onPressView}
                    onClickCard={onClickCard}
                    onExpand={onExpandCard}
                    onCollapse={(onCollapseCard)}
                    viewLabel={isOnThreadsPage ? "View" : "View in thread"}
                />
                {/* Judgement card reserves a fixed amout of space to not make the comment card change in width */}
                <View style={{ width: 300 }}>
                    {(
                        <HorizBox>
                            <Pad size={16} />
                            <JudgementCard modTask={modTask} modAuthor={modAuthor} taskWatchState={taskWatchState} isSelected={isSelected}/>
                        </HorizBox>
                    )}
                </View>
            </HorizBox>
        </View>
    );
}

export function NoModTasksBanner() {
    return (
        <View style={{ width: "fit-content" }}>
            <Pad size={16} />
            <Banner color={colorWhite}>
                <UtilityText label={"No comments."} />
            </Banner>
        </View>
    );
}

function ModTask({ modTaskKey = "", modAuthor, idx, task }) {
    //Subscribe to main queue item
    const modTask = useModulePublicData('moderation', ['queue', modTaskKey]);

    return (
        <View>
            {modTask?.type === "comment" && task && (
                <ModDashboardQueueListItem comment={task} modTask={modTask} modAuthor={modAuthor} idx={idx} />
            )}
        </View>
    );
}